<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>
ul,li{
  list-style: none;
}
.flex{
  display: flex;
  align-items: center;
}
.overflow_e {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all; 
  -webkit-box-orient: vertical;
}
</style>