<!-- 首页 -->
<template>
  <div class="home">
    <!-- 首页轮播图 -->
    <div class="index_banner">
      <el-carousel height="600px">
        <el-carousel-item class="index_banner_relative">
          <!-- <img src="../assets/images/banner.png" alt="" /> -->
          <div class="banner_content">
            <div class="content">
              <div class="banner_content_title no1">1分钟</div>
              <div class="banner_content_title no2">帮助企业搭建专属学习平台</div>
              <div class="banner_content__btns">
                <el-button class="btn" @click="SwitchStatus">免费体验</el-button>
              </div>
            </div>
            <img class="img" src="../assets/images/banner.png" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="show-box">
      <div class="box">
        <div class="content-1">
          <div class="title no1">资料下载：<br />高技能人才培训体系建设方案</div>
          <div class="tip">政策导向下，如果落实企业高技能人才培训？</div>
        </div>
        <div class="content-2">添加我的微信，立即获取资料</div>
      </div>
      <div class="box">
        <div class="content-1">
          <div class="title no1">资料下载：<br />国家级师资培训资料</div>
          <div class="tip">中华技能大奖获得者、全国劳动模范等授课...</div>
        </div>
        <div class="content-2">添加我的微信，立即获取资料</div>
      </div>
      <div class="box">
        <div class="content-1">
          <div class="title no1">职业院校：<br />学员招生、数字化转型怎么做？</div>
          <div class="tip">线上赋能传统教学模式</div>
        </div>
        <div class="content-2">添加我的微信，立即获取资料</div>
      </div>
      <div class="box">
        <div class="content-1">
          <div class="title no1">企业集团：<br />月度活动如何策划推广？</div>
          <div class="tip">知识竞赛、考试练兵...各种活动随便挑！</div>
        </div>
        <div class="content-2">添加我的微信，立即获取资料</div>
      </div>
    </div>

    <!-- 首页我们的优势 -->
    <div class="index_my_advantage">
      <div class="container my_advantage">
        <div class="my_advantage_title">打造全员提升的应用技术大学</div>
        <div class="my_advantage_description">企业培训一体化管理，个性化内容匹配，多产品运营赋能</div>
        <div class="index_module">
          <div class="box">
            <div class="title no1">课程丰富</div>
            <ul class="list">
              <li class="info">1000+国家级大师入驻</li>
              <li class="info">100+职业技能等级课程</li>
              <li class="info">10000+高技能人才培训课</li>
              <li class="info">30+行业专业课</li>
              <li class="info">课程、专题、培训班多样化内容展示</li>
            </ul>
          </div>
          
          <div class="box">
            <div class="title no1">多重功能保障学习</div>
            <ul class="list">
              <li class="info">智能数据分析</li>
              <li class="info">直播互动打卡</li>
              <li class="info">报名--学习--考试--领证一体化</li>
              <li class="info">多级管理角色</li>
              <li class="info">个性化功能定制</li>
            </ul>
          </div>

          <div class="box">
            <div class="title no1">玩转企业大学</div>
            <ul class="list">
              <li class="info">知识竞赛活动定期更新</li>
              <li class="info">个性化内容辅助定制</li>
              <li class="info">投票问卷调研小应用</li>
              <li class="info">学分积分系统激励</li>
              <li class="info">全天候运营团队支持</li>
            </ul>
          </div>

          <div class="box">
            <div class="title no1">渠道赋能</div>
            <ul class="list">
              <li class="info">行业上下游资源对接</li>
              <li class="info">人社业务打通</li>
              <li class="info">证书体系赋能</li>
              <li class="info">国家级师资引入</li>
              <li class="info">需求与资源对接</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="index_question">
      <div class="index_question_top">
        <!-- <div class="index_question_detail">
          个性化平台+定制化内容+专业化运营+线上线下融合
          <span style="color: #254ed4; cursor: pointer">更多领域解决方案></span>
        </div> -->
        <div class="index_question_title">全领域解决方案</div>
      </div>
      <div class="solution-list">
        <div class="solution-info" @click="knowMore">
          <img class="solution-img" src="@/assets/images/solution-1.png">
          <span>大型企业</span>
        </div>
        <div class="solution-info" @click="knowMore">
          <img class="solution-img" src="@/assets/images/solution-2.png">
          <span>政府机构</span>
        </div>
        <div class="solution-info" @click="knowMore">
          <img class="solution-img" src="@/assets/images/solution-3.png">
          <span>行业协会</span>
        </div>
        <div class="solution-info" @click="knowMore">
          <img class="solution-img" src="@/assets/images/solution-4.png">
          <span>职业院校</span>
        </div>
        <div class="solution-info" @click="knowMore">
          <img class="solution-img" src="@/assets/images/solution-5.png">
          <span>工会体系</span>
        </div>
        <div class="solution-info" @click="knowMore">
          <img class="solution-img" src="@/assets/images/solution-6.png">
          <span>人力资源机构</span>
        </div>
        <div class="solution-info" @click="knowMore">
          <img class="solution-img" src="@/assets/images/solution-7.png">
          <span>教培机构</span>
        </div>
        <div class="solution-info" @click="knowMore">
          <img class="solution-img" src="@/assets/images/solution-8.png">
          <span>中小企业</span>
        </div>
      </div>
    </div>

    <!-- 问题底部 -->
    <div class="question_bottom">
      <div class="container question_content">
        <div class="left">
          <div class="title">点击试用，免费体验KSK的强大功能体系</div>
          <div class="content">覆盖企业内训全场景，助力企业实现人才管理和技术赋能，您也可以与我们联系，定制整体解决方案</div>
        </div>
        <el-button class="right_question_btn" @click="SwitchStatus">免费体验</el-button>
      </div>
    </div>

    <!-- 讲师 -->
    <div class="index_teacher">
      <div class="index_teacher_title">师资力量</div>
      <div class="index_teacher_detail">覆盖专利产权、技能大赛、工作室建设、创新创造、技能提升、难题攻关、行业前沿、工匠精神、技能传承十大领域</div>
      <div class="teacher_list">
        <div class="classify_info">
          <img class="teacher_classify_ico" src="@/assets/images/teacher_classify_ico_1.png">
          <div class="content">
            <div class="title">技能专家</div>
            <div class="text">全国劳动模范、国家级技能大师、全国技术能手</div>
          </div>
        </div>
        <div class="classify_info">
          <img class="teacher_classify_ico" src="@/assets/images/teacher_classify_ico_2.png">
          <div class="content">
            <div class="title">行业专家</div>
            <div class="text">生产制造、机械行业、智能制造、职业健康等多领域专家</div>
          </div>
        </div>
        <div class="classify_info">
          <img class="teacher_classify_ico" src="@/assets/images/teacher_classify_ico_3.png">
          <div class="content">
            <div class="title">管理专家</div>
            <div class="text">科技政策、创新创造、成果申报、知识产权、精益管理等领域专家</div>
          </div>
        </div>
        <div class="classify_info">
          <img class="teacher_classify_ico" src="@/assets/images/teacher_classify_ico_4.png">
          <div class="content">
            <div class="title">学术专家</div>
            <div class="text">中国劳动关系学院教授、知名大学教授</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="index_teacher">
      <div class="index_teacher_title">大师风采</div>
      <div class="index_teacher_detail">（部分讲师展示）</div>
      <div class="teacher_list">
        <div class="teacher_info">
          <img class="avatar" src="@/assets/images/teacher-avatar-1.png">
          <div class="name">王  军</div>
          <div class="honor">全国劳动模范</div>
          <div class="honor">全国五一劳动奖章获得者</div>
          <div class="honor">中华技能大奖获得者</div>
          <div class="honor">全国技术能手</div>
          <div class="honor">两次获国家科技进步二等奖</div>
        </div>
        <div class="teacher_info">
          <img class="avatar" src="@/assets/images/teacher-avatar-2.png">
          <div class="name">李万君</div>
          <div class="honor">全国劳动模范</div>
          <div class="honor">全国五一劳动奖章获得者</div>
          <div class="honor">中华技能大奖获得者</div>
          <div class="honor">全国技术能手</div>
          <div class="honor">2018年大国工匠</div>
        </div>
        <div class="teacher_info">
          <img class="avatar" src="@/assets/images/teacher-avatar-3.png">
          <div class="name">李书乾</div>
          <div class="honor">全国劳动模范</div>
          <div class="honor">全国五一劳动奖章获得者</div>
          <div class="honor">享受国务院政府特殊津贴</div>
          <div class="honor">中华技能大奖获得者</div>
          <div class="honor">全国技术能手</div>
        </div>
        <div class="teacher_info">
          <img class="avatar" src="@/assets/images/teacher-avatar-4.png">
          <div class="name">卫建平</div>
          <div class="honor">全国五一劳动奖章获得者</div>
          <div class="honor">享受国务院政府特殊津贴</div>
          <div class="honor">全国技术能手</div>
          <div class="honor">全国百姓学习之星</div>
          <div class="honor">中国质量工匠</div>
        </div>
      </div>
    </div> -->

    <!-- 课程体系 -->
    <div class="index_class">
      <div class="index_class_title">课程体系</div>
      <div class="index_class_detail">课程内容可匹配不同行业、企业、工会、组织机构的学习培训需求</div>
      <div class="index_class_content">
        <!-- 通用素质 -->
        <div class="index_class_content_left" v-if="ClassContent == 1">
          <div class="title">
            <img src="../assets/images/通用.png" alt="" />
            <span style="font-weight: 700; font-size: 24px; margin-left: 10px">通用素质</span>
          </div>
          <p>5K+体系课程，全方位提升职工基础能力和素质</p>
          <div class="index_class_content_left_module">
            <div class="class_module">
              <img src="../assets/images/class_1.jpg" alt="" />
              <div>office三剑客</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_2.jpg" alt="" />
              <div>职场压力应对与情绪管理</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_3.jpg" alt="" />
              <div>新冠病毒防控微课</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_4.jpg" alt="" />
              <div>高情商私教课</div>
            </div>
          </div>
        </div>

        <!-- 建筑工程 -->
        <div class="index_class_content_left" v-if="ClassContent == 2">
          <div class="title">
            <img src="../assets/images/建筑.png" alt="" />
            <span style="font-weight: 700; font-size: 20px; margin-left: 10px">建筑工程</span>
          </div>
          <p>7K+各等级专业课程，系统提升职工岗位专业技能</p>
          <div class="index_class_content_left_module">
            <div class="class_module">
              <img src="../assets/images/class_9.jpg" alt="" />
              <div>造价工程师</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_10.jpg" alt="" />
              <div>BIM</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_11.jpg" alt="" />
              <div>一级建造师</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_12.jpg" alt="" />
              <div>砌筑工</div>
            </div>
          </div>
        </div>

        <!-- 前沿科技 -->
        <div class="index_class_content_left" v-if="ClassContent == 3">
          <div class="title">
            <img src="../assets/images/科技.png" alt="" />
            <span style="font-weight: 700; font-size: 20px; margin-left: 10px">前沿科技</span>
          </div>
          <p>5K+精品专业课，系统提升前沿科技人才技术水平，助力企业转型升级</p>
          <div class="index_class_content_left_module">
            <div class="class_module">
              <img src="../assets/images/class_17.jpg" alt="" />
              <div>大数据分析的道与术</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_18.jpg" alt="" />
              <div>工业机器人教程</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_19.jpg" alt="" />
              <div>工业互联网安全态势及防护策略</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_20.jpg" alt="" />
              <div>5G的前世今生</div>
            </div>
          </div>
        </div>

        <!-- 工会体系 -->
        <div class="index_class_content_left" v-if="ClassContent == 4">
          <div class="title">
            <img src="../assets/images/工会.png" alt="" />

            <span style="font-weight: 700; font-size: 20px; margin-left: 10px">工会体系</span>
          </div>
          <p>5K+核心课程，帮助工会干部系统提升工作能力</p>
          <div class="index_class_content_left_module">
            <div class="class_module">
              <img src="../assets/images/class_25.jpg" alt="" />
              <div>企业工会如何落实工作计划</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_26.jpg" alt="" />
              <div>职工心理疏导</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_27.jpg" alt="" />
              <div>基层工会收支管理规则</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_28.jpg" alt="" />
              <div>企业工会如何凝聚共识</div>
            </div>
          </div>
        </div>

        <!-- 生活服务 -->
        <div class="index_class_content_left" v-if="ClassContent == 5">
          <div class="title">
            <img src="../assets/images/生活.png" alt="" />
            <span style="font-weight: 700; font-size: 20px; margin-left: 10px">生活服务</span>
          </div>
          <p>3K+精品课程，精准匹配行业内职工培训需求</p>
          <div class="index_class_content_left_module">
            <div class="class_module">
              <img src="../assets/images/class_5.jpg" alt="" />
              <div>电子商务师</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_6.jpg" alt="" />
              <div>手机摄影摄像基础</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_7.jpg" alt="" />
              <div>中式面点师</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_8.jpg" alt="" />
              <div>家政服务员</div>
            </div>
          </div>
        </div>

        <!-- 生产制造 -->

        <div class="index_class_content_left" v-if="ClassContent == 6">
          <div class="title">
            <img src="../assets/images/生产.png" alt="" />
            <span style="font-weight: 700; font-size: 20px; margin-left: 10px">生产制造</span>
          </div>
          <p>1W+高精尖课和专业课，帮助企业人才梯队提升技能</p>
          <div class="index_class_content_left_module">
            <div class="class_module">
              <img src="../assets/images/class_13.jpg" alt="" />
              <div>最新安全生产法解读</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_14.jpg" alt="" />
              <div>班组建设与时间管理</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_15.jpg" alt="" />
              <div>二氧化碳气体保护焊</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_16.jpg" alt="" />
              <div>全面质量管理基本知识</div>
            </div>
          </div>
        </div>

        <!-- 企业管理 -->

        <div class="index_class_content_left" v-if="ClassContent == 7">
          <div class="title">
            <img src="../assets/images/企业.png" alt="" />
            <span style="font-weight: 700; font-size: 20px; margin-left: 10px">企业管理</span>
          </div>
          <p>9K+核心课程，满足不同企业为人才梯队定制专属课程体系</p>
          <div class="index_class_content_left_module">
            <div class="class_module">
              <img src="../assets/images/class_21.jpg" alt="" />
              <div>财务管理</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_22.jpg" alt="" />
              <div>项目管理</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_23.jpg" alt="" />
              <div>中层干部的管理与培养</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_24.jpg" alt="" />
              <div>领导力</div>
            </div>
          </div>
        </div>

        <!-- 培训考证 -->

        <div class="index_class_content_left" v-if="ClassContent == 8">
          <div class="title">
            <img src="../assets/images/培训.png" alt="" />
            <span style="font-weight: 700; font-size: 20px; margin-left: 10px">培训考证</span>
          </div>
          <p>1K+专业课程，支持职工参加职业等级认定考试</p>
          <div class="index_class_content_left_module">
            <div class="class_module">
              <img src="../assets/images/class_29.jpg" alt="" />
              <div>美容师</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_30.jpg" alt="" />
              <div>机床电子控制技术</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_31.jpg" alt="" />
              <div>茶艺师（四级）</div>
            </div>
            <div class="class_module">
              <img src="../assets/images/class_32.jpg" alt="" />
              <div>车工（三级）</div>
            </div>
          </div>
        </div>

        <!-- 课程提下右侧内容 -->
        <!-- 通用素质 -->
        <div class="index_class_content_right">
          <!-- 通用素质 -->
          <div
            class="index_class_content_right_module"
            @click="ClassStyle('1')"
            :class="ClassContent == 1 ? 'index_class_content_left_active' : ''"
          >
            <div class="index_class_content_right_module_title">
              <img src="../assets/images/通用.png" alt="" />
              <span>通用素质</span>
            </div>
            <p>5K+体系课程，全方位提升职工基础能力和素质</p>
            <img
              src="../assets/images/通用_bottom.png"
              alt=""
              class="class_bottom"
            />
          </div>

          <!-- 建筑工程 -->
          <div
            class="index_class_content_right_module"
            @click="ClassStyle('2')"
            :class="ClassContent == 2 ? 'index_class_content_left_active' : ''"
          >
            <div class="index_class_content_right_module_title">
              <img src="../assets/images/建筑.png" alt="" />
              <span>建筑工程</span>
            </div>
            <p>7K+各等级专业课程，系统提升职工岗位专业技能</p>
            <img
              src="../assets/images/建筑_bottom.png"
              alt=""
              class="class_bottom"
            />
          </div>

          <!-- 前沿科技 -->
          <div
            class="index_class_content_right_module"
            @click="ClassStyle('3')"
            :class="ClassContent == 3 ? 'index_class_content_left_active' : ''"
          >
            <div class="index_class_content_right_module_title">
              <img src="../assets/images/科技.png" alt="" />
              <span>前沿科技</span>
            </div>
            <p>5K+精品专业课，系统提升前沿科技人才技术水平，助力企业转型升级</p>
            <img
              src="../assets/images/科技_bottom.png"
              alt=""
              class="class_bottom"
            />
          </div>

          <!-- 工会体系 -->
          <div
            class="index_class_content_right_module"
            @click="ClassStyle('4')"
            :class="ClassContent == 4 ? 'index_class_content_left_active' : ''"
          >
            <div class="index_class_content_right_module_title">
              <img src="../assets/images/工会.png" alt="" />
              <span>工会体系</span>
            </div>
            <p>5K+核心课程，帮助工会干部系统提升工作能力</p>
            <img
              src="../assets/images/工会_bottom.png"
              alt=""
              class="class_bottom"
            />
          </div>

          <!-- 生活服务 -->
          <div
            class="index_class_content_right_module"
            @click="ClassStyle('5')"
            :class="ClassContent == 5 ? 'index_class_content_left_active' : ''"
          >
            <div class="index_class_content_right_module_title">
              <img src="../assets/images/生活.png" alt="" />
              <span>生活服务</span>
            </div>
            <p>3K+精品课程，精准匹配行业内职工培训需求</p>
            <img
              src="../assets/images/生活_bottom.png"
              alt=""
              class="class_bottom"
            />
          </div>

          <!-- 生产制造 -->
          <div
            class="index_class_content_right_module"
            @click="ClassStyle('6')"
            :class="ClassContent == 6 ? 'index_class_content_left_active' : ''"
          >
            <div class="index_class_content_right_module_title">
              <img src="../assets/images/生产.png" alt="" />
              <span>生产制造</span>
            </div>
            <p>1W+高精尖课和专业课，帮助企业人才梯队提升技能</p>
            <img
              src="../assets/images/生产_bottom.png"
              alt=""
              class="class_bottom"
            />
          </div>

          <!-- 企业管理 -->
          <div
            class="index_class_content_right_module"
            @click="ClassStyle('7')"
            :class="ClassContent == 7 ? 'index_class_content_left_active' : ''"
            style="margin: 0"
          >
            <div class="index_class_content_right_module_title">
              <img src="../assets/images/企业.png" alt="" />
              <span>企业管理</span>
            </div>
            <p>9K+核心课程，满足不同企业为人才梯队定制专属课程体系</p>
            <img
              src="../assets/images/企业_bottom.png"
              alt=""
              class="class_bottom"
            />
          </div>

          <!-- 培训考证 -->
          <div
            class="index_class_content_right_module"
            @click="ClassStyle('8')"
            :class="ClassContent == 8 ? 'index_class_content_left_active' : ''"
          >
            <div class="index_class_content_right_module_title">
              <img src="../assets/images/培训.png" alt="" />
              <span>培训考证</span>
            </div>
            <p>1K+专业课程，支持职工参加职业等级认定考试</p>
            <img
              src="../assets/images/培训_bottom.png"
              alt=""
              class="class_bottom"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 成功案例 -->
    <div class="index_case" id="indexCase">
      <div class="container case_title">
        <div class="big_case_title">成功案例</div>
        <div class="content_case_title">为2万余家龙头企业、政府系统、工会系统、职业院校、培训机构提供互联网+服务</div>

        <div class="case_content">
          <div class="case_content_left">
            <img
              src="../assets/images/中工云课堂.png"
              alt=""
              v-if="img_status == 1"
              style="border-radius:10px;border:1px solid #fff"
            />
            <img
              src="../assets/images/安徽五一学堂.png"
              alt=""
              v-if="img_status == 2"
              style="border-radius:10px;border:1px solid #fdef00"

            />
            <img
              src="../assets/images/技能强国.png"
              alt=""
              v-if="img_status == 0"
               style="border-radius:10px;border:1px solid #fff"
            />
            <!-- <img
              src="../assets/images/success_logo3.png"
              alt=""
              v-if="img_status == 2"
            /> -->
          </div>

          <div class="case_content_right">
            <template v-if="img_status == 0">
              <div class="case_content_right_title">技能强国</div>
              <div class="case_content_right_content">
                发改委、工信部、人社部、全国总工会四部委联合发文推荐平台<br />
                年浏览量过亿<br />
                支持千万用户在线学习<br />
                每周1期大国工匠直播<br />
                课程内容适配30+行业
              </div>
            </template>

            <template v-if="img_status == 3">
              <div class="case_content_right_title">中建七局</div>
              <div class="case_content_right_content">
                基于KSK开放平台的技术、内容和运营，中建七局建设工匠学院线上学习平台，包含上万门课程，覆盖集团公司100+工种/岗位，学员覆盖建筑行业数千家企业，累计培训700万+学员。
              </div>
            </template>

            <template v-if="img_status == 1">
              <div class="case_content_right_title">中工云课堂</div>
              <div class="case_content_right_content">
                满足企业组织管理、高技能人才交流需求<br />
                1000+国家级大师入驻<br />
                大师定期直播分享互动<br />
                覆盖创新创造、技能大赛、专利申报等高技能人才培训课程<br />
                支持在线选大师授课、选企业参访
              </div>
            </template>

            <template v-if="img_status == 2">
              <div class="case_content_right_title">安徽五一学堂</div>
              <div class="case_content_right_content">
                获评省级“十佳”平台<br />
                20W+职工在线学习<br />
                多级角色管理 智能数据呈现<br />
                满足报名--学习--考试--领证闭环<br />
                热门活动定期更新
              </div>
            </template>

            <div class="case_content_right_module">
              <el-carousel
                :interval="5000"
                type="card"
                height="130px"
                style="width: 800px"
                indicator-position="none"
                @change="changeImg"
              >
                <el-carousel-item v-for="(item, index) in ImgList" :key="index">
                  <img :src="item.src" alt="" style="border-radius:10px"/>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 业务覆盖 -->
    <div class="index_business">
      <div class="container business_title">
        <div class="title">业务覆盖</div>
        <div class="content">用科技创新力助推全国人才发展服务的管理建设</div>

        <div class="map_num">
          <div>目前我们的代理业务已覆盖</div>
          <div>全国<span>22</span>个省</div>
          <div><span>100</span>余个地方</div>
          <div>平台企业注册总量<span>30000</span>余家</div>
          <div>平台累计培训总人数突破<span>3600000</span></div>
        </div>

        <div class="map_pic">
          <img src="../assets/images/map.png"/>
        </div>
      </div>
    </div>

    <!-- 品牌合作 -->
    <div class="index_brand">
      <div class="container">
        <div class="index_brand_title">品牌合作</div>
        <div class="index_brand_content">
          <img class="img" :src="require('@/assets/images/pinpai-' + i + '.png')" v-for="i in 30" />
        </div>
      </div>
    </div>
    <RegiSter :dialogVisible="dialogVisible" @changeCut="changeCut"></RegiSter>
  </div>
</template>

<script>
import echarts from "echarts";
import RegiSter from "@/components/Register.vue";
require("echarts/map/js/china");
// import $ from 'jquery'

export default {
  name: "Index",
  components: {
    RegiSter: RegiSter,
  },
  data() {
    return {
      dialogVisible: false,

      //tab切换列表
      TabsList: [
        "大型企业",
        "政府机构",
        "行业协会",
        "职业院校",
        "工会体系",
        "人力资源企业",
        "教培机构",
        "中小企业",
      ],
      //tab切换类型
      tabsstatus: "大型企业",
      //配置地图高亮的列表

      //切换讲师类型
      imgWidth: 1,
      //课程体系样式
      ClassContent: 1,
      // 成功案例图片列表
      ImgList: [
        {
          id: 1,
          src: require("../assets/images/技能强国1.png"),

        },
        {
          id: 2,
          src: require("../assets/images/中工云课堂1.png"),
        },
        // {
        //   id: 3,
        //   src: require("../assets/images/success_right_3.png"),
        // },
        {
          id: 4,
          src: require("../assets/images/安徽五一学堂1.png"),

        },
      ],
      img_status: 0,
    };
  },
  methods: {
    //tab切换激活样式
    tabchange(val) {
      this.tabsstatus = val;
    },
    ActiveLeftImg(val) {
      this.imgWidth = val;
    },
    ClassStyle(val) {
      this.ClassContent = val;
    },
    changeCut(res) {
      this.dialogVisible = res;
    },
    SwitchStatus() {
      this.dialogVisible = true;
    },
    changeImg(val) {
      this.img_status = val;
    },
    //了解更多
    knowMore(){
      this.$parent.contactUs();
    },
    //是否M端
    isM(){
      const userAgentInfo = navigator.userAgent;
      const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];

      let flag = false;

      for (var v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) > 0) {
              flag = true;
              break
          }
      }
      return flag
    }
  },
  mounted() {
    if(this.isM() && window.location.host=='open.kskstudy.com'){
      window.location.href = 'http://m.open.kskstudy.com'
    }
    // 初始化echarts实例
    // this.chinachart = echarts.init(document.getElementById("china_map"));
    // 进行相关配置
    // this.chartOption = {
    //   tooltip: {
    //     // 鼠标移到图里面的浮动提示框
    //     // formatter详细配置： https://echarts.baidu.com/option.html#tooltip.formatter
    //     formatter(params, ticket, callback) {
    //       // params.data 就是series配置项中的data数据遍历
    //       let localValue, perf, downloadSpeep, usability, point;
    //       if (params.data) {
    //         localValue = params.data.value;
    //         perf = params.data.perf;
    //         downloadSpeep = params.data.downloadSpeep;
    //         usability = params.data.usability;
    //         point = params.data.point;
    //       } else {
    //         // 为了防止没有定义数据的时候报错写的
    //         localValue = 0;
    //         perf = 0;
    //         downloadSpeep = 0;
    //         usability = 0;
    //         point = 0;
    //       }
    //       let htmlStr = `
    //       <div style='font-size:18px;'> ${params.name}</div>`;
    //       return htmlStr;
    //     },
    //     // backgroundColor:"#ff7f50",//提示标签背景颜色
    //     // textStyle:{color:"#fff"} //提示标签字体颜色
    //   },
    //   // visualMap的详细配置解析：https://echarts.baidu.com/option.html#visualMap
    //   visualMap: {
    //     // 左下角的颜色区域
    //     type: "piecewise", // 定义为分段型 visualMap
    //     min: 0,
    //     max: 1000,
    //     itemWidth: 40,
    //     bottom: 60,
    //     left: 20,
    //     pieces: [
    //       // 自定义『分段式视觉映射组件（visualMapPiecewise）』的每一段的范围，以及每一段的文字，以及每一段的特别的样式
    //       { gt: 10, lte: 200, label: "覆盖地区", color: "#254ed4" }, // (10, 200]
    //     ],
    //   },
    //   // geo配置详解： https://echarts.baidu.com/option.html#geo
    //   geo: {
    //     // 地理坐标系组件用于地图的绘制
    //     map: "china", // 表示中国地图
    //     // roam: true, // 是否开启鼠标缩放和平移漫游
    //     zoom: 1.2, // 当前视角的缩放比例（地图的放大比例）
    //     label: {
    //       show: true,
    //     },
    //     itemStyle: {
    //       // 地图区域的多边形 图形样式。
    //       borderColor: "#ccc",
    //       emphasis: {
    //         // 高亮状态下的多边形和标签样式
    //         shadowBlur: 0,
    //         shadowColor: "#98B9EF",
    //       },
    //     },
    //     background: "red",
    //   },
    //   series: [
    //     {
    //       name: "", // 浮动框的标题（上面的formatter自定义了提示框数据，所以这里可不写）
    //       type: "map",
    //       geoIndex: 0,
    //       label: {
    //         show: true,
    //       },
    //       // 这是需要配置地图上的某个地区的数据，根据后台的返回的数据进行拼接（下面是我定义的假数据）
    //       data: this.list,
    //     },
    //   ],
    // };
    // 使用刚指定的配置项和数据显示地图数据
    // this.chinachart.setOption(this.chartOption);
  },
};
</script>
<style  lang='less'>
// .home {
//   margin-top: 85px;
// }
//轮播图
.index_banner {
  width: 100%;
  height: 600px;
  .el-carousel--horizontal{
    overflow-x: visible;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .index_banner_relative {
    background: url('~@/assets/images/banner-bg.png') no-repeat right bottom/cover;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .banner_content {
      width: 1440px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      .banner_content_title {
        color: #fff;
        &.no1{
          font-size: 67px;
          font-weight: bold;
        }
        &.no2{
          font-size: 48px;
        }
      }
      
      .img{
        width: 625px;
        height: 475px;
      }
      
      .banner_content__btns {
        margin-top: 30px;
        .btn {
          width: 249px;
          height: 74px;
          background: linear-gradient(90deg, #F68150 0%, #F9B054 100%);
          border-radius: 37px;
          font-size: 28px;
          font-weight: 400;
          color: #FFF;
        }
      }
    }
  }
}
//优势
.index_my_advantage {
  width: 100%;
  height: 570px;
  background: #fff;
  .my_advantage {
    width: 1418px;
    padding-top: 80px;
    text-align: center;
    .my_advantage_title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 20px;
    }
    .my_advantage_description {
      height: 26px;
      font-size: 16px;
      color: #333333;
      margin-bottom: 60px;
    }
    .index_module {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .box{
        width: 342px;
        background: linear-gradient(135deg, #FFFFFF 0%, #F4F7FC 100%);
        box-shadow: 0px 3px 8px 1px rgba(20,53,193,0.16), inset 3px 3px 25px 1px rgba(20,53,193,0.05);
        border-radius: 0 0 10px 10px;
        .title{
          box-sizing: border-box;
          width: 100%;
          height: 58px;
          line-height: 58px;
          padding-left: 20px;
          font-size: 22px;
          color: #FFF;
          &.no1{
            background: url('~@/assets/images/title-bg-1.png') no-repeat center center/100% 100%;
          }
        }
        .list{
          box-sizing: border-box;
          width: 100%;
          padding: 16px;
          border: 2px solid #FFFFFF;
          border-radius: 0 0 10px 10px;
        }
        .info{
          list-style: none;
          font-size: 18px;
          color: #333;
          line-height: 36px;
          cursor: default;
          padding-left: 18px;
          background: url('~@/assets/images/spot-yellow.png') no-repeat left center/11px;
          &:hover{
            color: #2659E3;
            background: url('~@/assets/images/spot-blue.png') no-repeat left center/11px;
          }
        }
      }
    }
  }
}

//问题
.index_question {
  width: 100%;
  height: 100%;
  padding-top: 90px;
  background: url("../assets/images/痛点_bgc.png") no-repeat center/cover;

  .index_question_top {
    text-align: center;
    margin-bottom: 42px;

    .index_question_title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      margin-top: 20px;
      .index_question_detail {
        font-size: 16px;
        color: #333333;
      }
    }
  }
  .solution-list{
    width: 980px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .solution-info{
      width: 125px;
      margin-right: 160px;
      margin-bottom: 62px;
      text-align: center;
      cursor: pointer;
      &:nth-child(4n){
        margin-right: 0;
      }
    }
    .solution-img{
      width: 100%;
    }
  }
  .index_question_tab {
    height: 88px;
    border: 2px solid #ffffff;
    box-shadow: 3px 4px 10px rgba(20, 53, 193, 0.14);
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 50px;
    box-sizing: border-box;
    div {
      width: 100%;
      height: 100%;
      line-height: 88px;
      text-align: center;
      font-size: 16px;
      color: #333333;
      cursor: pointer;
    }
  }
  //大型企业
  .bigcompoey {
    width: 100%;
    height: 450px;
    padding-top: 20px;
    box-sizing: border-box;
    .bigcompoey_content {
      display: flex;
      width: 100%;
      height: 330px;
      border: 2px solid #ffffff;
      border-radius: 4px;
      border: 2px solid #ffffff;
      box-shadow: 3px 4px 10px rgba(20, 53, 193, 0.14);
      border-radius: 4px;
      padding: 55px 50px 0px 50px;
      box-sizing: border-box;
      .bigcompoey_content_left {
        width: 50%;
        .left_title {
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 16px;
        }
        .small_title {
          width: 430px;
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 55px;
        }
        .second_title {
          margin-bottom: 16px;
          font-size: 20px;
          font-weight: 700;
          img {
            margin-right: 10px;
          }
        }
      }
      .bigcompoey_content_right {
        width: 50%;
        .right_title {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 16px;
          img {
            margin-right: 10px;
          }
        }
        p {
          font-size: 16px;
          line-height: 30px;
        }
        .bigcomponey_btn {
          background: #254ed4;
          box-shadow: 0px 0px 4px rgba(20, 53, 193, 0.26);
          font-size: 12px;
          color: #fcfcfe;
          line-height: 24px;
          text-align: center;
          margin-top: 24px;
          padding: 5px 20px !important;
        }
      }
    }
    .bigcompoey_right_img1 {
      position: absolute;
      right: 170px;
      top: 87px;
      img {
        width: 409px;
        height: 272px;
        border-radius: 5px;
      }
    }
    .bigcompoey_right_img2 {
      position: absolute;
      right: 0;
      top: 310px;
      img {
        width: 409px;
        height: 272px;
        border-radius: 5px;
      }
    }
  }
}

//问题的底部
.question_bottom {
  width: 100%;
  height: 204px;
  background: url("../assets/images/question_bg.png") no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .question_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .title {
        height: 50px;
        font-size: 20px;
        line-height: 30px;
      }
    }
    .right_question_btn {
      background: #fff;
      box-shadow: 0px 0px 6px rgba(20, 53, 193, 0.16);
      border-radius: 4px;
      color: #254ed4;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      padding: 9px 29px;
    }
  }
}
.index_teacher {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .index_teacher_title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .index_teacher_detail {
    width: 700px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin: 20px 0 60px 0;
  }
  .teacher_list {
    width: 1416px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .classify_info{
      box-sizing: border-box;
      width: 703px;
      height: 152px;
      padding: 0 30px;
      background: linear-gradient(45deg, #FEFEFE 0%, #F4F7FC 100%);
      box-shadow: 0px 3px 8px 1px rgba(20,53,193,0.16), inset 3px 3px 25px 1px rgba(20,53,193,0.05);
      border-radius: 5px;
      border: 3px solid #FFFFFF;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .teacher_classify_ico{
        width: 84px;
        margin-right: 20px;
      }
      .content{
        width: 700px;
      }
      .title{
        font-size: 24px;
        font-weight: bold;
        color: #2659E3;
        margin-bottom: 10px;
      }
      .text{
        font-size: 20px;
        font-weight: 400;
        color: #333333;
      }
    }
    .teacher_info{
      box-sizing: border-box;
      width: 334px;
      height: 458px;
      padding-top: 30px;
      background: linear-gradient(135deg, #FEFEFE 0%, #F4F7FC 100%);
      box-shadow: 0px 3px 8px 1px rgba(20,53,193,0.16), inset 3px 3px 25px 1px rgba(20,53,193,0.05);
      border-radius: 5px;
      border: 3px solid #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar{
        height: 138px;
        margin-bottom: 8px;
      }
      .name{
        font-size: 30px;
        font-weight: bold;
        color: #2659E3;
        line-height: 40px;
        margin-bottom: 10px;
      }
      .honor{
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
      }
    }
  }
}

//课程体系
.index_class {
  width: 1416px;
  padding-top: 90px;
  text-align: center;
  margin: 0 auto;
  .index_class_title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  .index_class_detail {
    font-size: 16px;
    color: #333333;
    margin-bottom: 50px;
  }
  .index_class_content {
    display: flex;
    .index_class_content_left {
      width: 557px;
      height: 492px;
      margin-right: 10px;
      background: linear-gradient(135deg, #FEFEFE 0%, #F4F7FC 100%);
      border: 3px solid #fff;
      box-shadow: inset 3px 3px 25px 1px rgba(20,53,193,0.05);
      border-radius: 4px;
      padding: 91px 20px 0 20px;
      box-sizing: border-box;
      text-align: left;
      p {
        margin: 32px 0 76px 0;
        color: #333333;
        font-size: 20px;
      }
      .index_class_content_left_module {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .class_module {
          width: 120px;
          img {
            width: 100%;
          }
          div {
            padding: 0 10px;
            height: 50px;
            text-align: center;
            margin-top: 15px;
            font-size: 12px;
          }
        }
      }
    }
    .index_class_content_right {
      display: flex;
      flex-wrap: wrap;
      width: 840px;
      justify-content: space-between;
      cursor: pointer;
      overflow: hidden;
      .index_class_content_right_module {
        box-sizing: border-box;
        width: 194px;
        height: 240px;
        padding: 40px 26px 0 26px;
        border-radius: 5px;
        border: 3px solid #FFFFFF;
        background: linear-gradient(135deg, #FEFEFE 0%, #F4F7FC 100%);
        box-shadow: inset 3px 3px 25px 1px rgba(20,53,193,0.05);
        text-align: left;
        margin-bottom: 12px;
        position: relative;
        .index_class_content_right_module_title {
          margin-bottom: 30px;
          img {
            width: 22px;
            height: 22px;
          }
          span {
            font-weight: 700;
            margin: 10px;
          }
        }
        p {
          font-size: 14px;
        }
        .class_bottom {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 9999;
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

//成功案例
.index_case {
  height: 676px;
  margin-top: 90px;
  background: #254fd4;
  padding-top: 90px;
  box-sizing: border-box;
  color: #fff;
  .case_title{
    width: 1340px;
  }
  .big_case_title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }
  .content_case_title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 50px;
  }
  .case_content {
    display: flex;
    height: 367px;
    .case_content_left {
      width: 554px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .case_content_right {
      padding-top: 15px;
      margin-left:66px;
      color: #ffffff;

      .case_content_right_title {
        font-size: 20px;
        font-weight: bold;
      }
      .case_content_right_content {
        font-size: 16px;
        line-height: 25px;
        margin-top: 60px;
        height: 80px;
      }
      .case_content_right_module {
        width: 700px;
        height: 200px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
//业务覆盖
.index_business {
  padding: 90px 0 50px 0;
  background: #f5f8fa;
  .business_title {
    width: 1425px;
    text-align: center;
    overflow: hidden;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }
    .content {
      font-size: 16px;
      color: #333333;
      margin-top: 20px;
    }
  }
  .map_num {
    float: left;
    height: 315px;
    margin-top: 240px;
    text-align: left;
    div {
      line-height: 40px;
      font-size: 16px;
      color: #333333;
      span {
        font-size: 30px;
        font-weight: 700;
        color: #254ed4;
        margin: 0 4px;
      }
    }
  }
  .map_pic {
    float: right;
    img {
      width: 80%;
      float: right;
    }
  }
}

//品牌合作
.index_brand {
  padding: 70px 0 150px 0;
  height: 100%;
  background-color: #fff;
  .container{
    width: 1425px;
  }
  .index_brand_title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 60px;
  }
  .index_brand_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;

    img {
      width: 273px;
      height: 203px;
      box-shadow: 2px 2px 5px rgba(20, 53, 193, 0.14);
      margin-bottom: 14px;
      &:hover {
        transition: all 0.5s;
        margin-top: -5px;
      }
    }
  }
}
.fixed_right {
  width: 44px;
  height: 134px;
  border: 1px solid #ffffff;
  background: linear-gradient(180deg, #f3f5f8 0%, #ffffff 100%);
  box-shadow: 0px 2px 3px rgba(20, 53, 193, 0.16);
  border-radius: 25px;
  position: fixed;
  right: 77px;
  top: 500px;
  color: #1435c1;
  font-size: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999;
  cursor: pointer;
  .fixed_right_text div {
    margin: 3px 0;
  }
}
.fixed_right_go_top {
  position: fixed;
  right: 77px;
  top: 639px;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  text-align: center;
  line-height: 39px;
  box-shadow: 0px 2px 3px rgba(20, 53, 193, 0.16);
  z-index: 99999;
  cursor: pointer;
  background-color: #fff;
}
//激活的选项样式
.active_index_question_tab {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #254ed4 !important;
  border-bottom: 3px solid #254ed4;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.index_class_content_left_active {
  background: url('../assets/images/课程_bgc.png') no-repeat !important;
  background-size: cover !important;
  color: #fff !important;
}

.show-box{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -30px;
  position: relative;
  z-index: 8;
  .box{
    width: 350px;
    height: 140px;
    border-radius: 10px;
    background: linear-gradient(90deg, #FFFFFF 0%, #F4F7FC 100%);
    box-shadow: 0px 3px 8px 1px rgba(20,53,193,0.16), inset 3px 3px 10px 1px rgba(20,53,193,0.1);
    margin: 0 5px;
    position: relative;
    &:hover{
      .content-1{
        display: none;
      }
      .content-2{
        display: flex;
        height: 221px;
      }
    }
  }
  .title{
    font-size: 18px;
    color: #333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &::before{
      content: '';
      display: block;
      width: 54px;
      height: 54px;
      border-radius: 5px;
      margin-right: 14px;
    }
    &.no1::before{
      background: url('~@/assets/images/code-img.png') no-repeat center center/45px 45px #fff;
    }
  }
  .tip{
    font-size: 16px;
    font-weight: 300;
    color: #999;
  }
  .content-1,
  .content-2{
    display: block;
    box-sizing: border-box;
    padding: 29px 0 0 18px;
    border-radius: 10px;
    border: 2px solid #FFFFFF;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .content-2{
    display: none;
    padding: 41px 0 0;
    background: url('~@/assets/images/box-active-bg.png') no-repeat center center/cover;
    border: none;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    color: #FFF;
    &::before{
      content: '';
      display: block;
      width: 100px;
      height: 100px;
      background: url('~@/assets/images/code-active-img-1.jpg') no-repeat center center/100% 100% #fff;
      box-shadow: 0px 0px 8px 1px rgba(20,53,193,0.16);
      border-radius: 5px;
      margin-bottom: 6px;
    }
  }
}
</style>