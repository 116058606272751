import axios from 'axios'

var instance =axios.create({
    baseURL:'https://openapi.kskstudy.com/api',
    timeout: 50000
})

instance.interceptors.response.use((config)=>{
    // console.log(config)
    return config.data
},(err)=>{
    return new Promise(err)
})
export default  instance