<!-- 首页 -->
<template>
  <div class="index">
    
      <!-- 首页头部 -->
      <div :class="(height >= 10||this.$store.getters.path!=='/index') ? 'index_topmenu' : 'index_topmenu_active'">
        <div class="container top_menu">
          <img :src="(height >= 10||this.$store.getters.path!=='/index') ? require('@/assets/images/logo.png') : require('@/assets/images/top_logo1.png')" class="leftlogo" />
          <div class="left_title cursor">打造企业全员提升的应用技术大学</div>
          <div class="right_menu">
            <div
              @click="CutStyle(item)"
              :class="{'active_right_menu': path==item.url}"
              v-for="(item, index) in MenuList"
              :key="index"
            >
              {{ item.name }}
            </div>
            <el-button class="index_btn" @click="SwitchStatus">免费体验</el-button>
          </div>
        </div>
      </div>
    <router-view></router-view>
    <!-- 底部footer -->
    <div class="footer_box">
      <div class="foot_content">
        <div class="list_cont">
          <div class="left_logo_box">
            <p class="logoImg">
              <img src="@/assets/foot_img/logo.png" alt="">
            </p>
            <p class="text">1分钟帮助企业</p>
            <p class="text">搭建专属学习平台</p>
          </div>
          <div class="link_list">
            <ul>
              <li class="title">培训服务</li>
              <li><a href="http://npx.kskstudy.com/course_learn" target="_blank" rel="noopener noreferrer">国家级师资定制授课</a></li>
              <li><a href="http://npx.kskstudy.com/liveList" target="_blank" rel="noopener noreferrer">大国工匠技术大讲堂</a></li>
              <li><a href="http://npx.kskstudy.com/subjectList" target="_blank" rel="noopener noreferrer">全年职工培训计划</a></li>
              <li><a href="http://npx.kskstudy.com/cloudCourse" target="_blank" rel="noopener noreferrer">线下学习交流</a></li>
              <li><a href="https://www.wenjuan.com/s/ZBRjQrI/#" target="_blank" rel="noopener noreferrer">定制培训方案</a></li>
            </ul>
            <ul>
              <li class="title">职工活动</li>
              <li class="hover_item"  @click="showCodeImg('0')">疗休养 </li>
              <li class="hover_item"  @click="showCodeImg('1')">工会活动</li>
              <li><a href="http://npx.kskstudy.com/activityImg" target="_blank" rel="noopener noreferrer">竞赛活动</a></li>
              <li><a href="https://mp.weixin.qq.com/s?__biz=MzU4ODUyMTE3NA==&mid=2247487883&idx=1&sn=585b9e8d442d4ea0306654e49ec22da9&chksm=fddada32caad5324613e9a0d4d143d454fee093e563154c7d32242af46015b5c73224728b0d7#rd" target="_blank" rel="noopener noreferrer">创新工作室交流</a></li>
              <li><a href="https://www.wenjuan.com/s/I3Ajye5/#" target="_blank" rel="noopener noreferrer">定制活动方案</a></li>
            </ul>
            <ul>
              <li class="title">合作类型</li>
              <li><a href="https://mp.weixin.qq.com/s?__biz=MzU4ODUyMTE3NA==&mid=2247487875&idx=1&sn=616e39ef0f8360c9dc59e277670e1447&chksm=fddada3acaad532ca544363f92c73abbc22d537f1df92414e046d9352eda763b37f6ba9a3a67#rd" target="_blank" rel="noopener noreferrer">城市合伙人</a></li>
              <li><a href="https://www.wenjuan.com/s/UZBZJvTniA9/#" target="_blank" rel="noopener noreferrer">渠道合作</a></li>
              <li><a href="https://www.wenjuan.com/s/UZBZJvTniA9/#" target="_blank" rel="noopener noreferrer">内容合作</a></li>
              <li><a href="http://landing.kjcxchina.com/" target="_blank" rel="noopener noreferrer">讲师入驻</a></li>
              <li @click="SwitchStatus">企业入驻</li>
            </ul>
            <ul>
              <li class="title">关于我们</li>
              <li><a href="http://open.kskstudy.com/CompanyProfile" target="_blank" rel="noopener noreferrer">公司简介</a></li>
              <li><a href="http://open.kskstudy.com/CustomerCase" target="_blank" rel="noopener noreferrer">客户案例</a></li>
              <li><a href="http://open.kskstudy.com/News" target="_blank" rel="noopener noreferrer">新闻动态</a></li>
              <li class="hover_item" @click="showCodeImg('2')">干货资料 </li>
            </ul>
            <ul class="about_us">
              <li class="title">联系我们</li>
              <li>400-0123-823</li>
              <li class="img">
                <img src="@/assets/foot_img/open_code.jpg" alt="">
              </li>
            </ul>
          </div>
        </div>
        <div class="link_box">
          <p class="text">友情链接</p>
                
          <ul>
            <li><a href="https://skills.kjcxchina.com/" target="_blank" rel="noopener noreferrer"> 技能强国</a></li>
            <li><a href="http://sco.kjcxchina.com/" target="_blank" rel="noopener noreferrer">上合组织国家工匠研修学堂</a></li>
            <!-- <li><a href="https://sco.tatpworkers.com/" target="_blank" rel="noopener noreferrer">上合组织国家工匠研修学堂</a></li> -->
            <li><a href="https://anhui.kjcxchina.com/" target="_blank" rel="noopener noreferrer">安徽工会五一学堂</a></li>
            <li><a href="https://hubei.kjcxchina.com/" target="_blank" rel="noopener noreferrer">湖北省总工会职工免费课堂平台</a></li>
            <li><a href="http://nmbeijiang.kjcxchina.com/" target="_blank" rel="noopener noreferrer">内蒙古自治区总工会职工学习平台</a></li>
            <li><a href="http://sichuan.kjcxchina.com/" target="_blank" rel="noopener noreferrer">四川省总工会线上工匠学院</a></li>
            <li><a href="http://npx.kskstudy.com/" target="_blank" rel="noopener noreferrer">中工云课堂</a></li>
          </ul>
        </div>
        <div class="text_box">
          <p>技术&运营支持：中工科创      Copyright©2015-2023 kskstudy.com All Rights Reseverd</p>
        </div>
      </div>
    </div>
    <div class="show_code_box" v-if="codeImgState">
      <div class="img_box">
        <p class="close" @click="codeImgState = false">×</p>
        <p class="img">
          <img v-if="imgIndex==0" src="@/assets/foot_img/lxy.jpg" alt="">
          <img v-if="imgIndex==1" src="@/assets/foot_img/hhd.jpg" alt="">
          <img v-if="imgIndex==2" src="@/assets/foot_img/open_code1.jpg" alt="">
        </p>
      </div>
    </div>
    
    <!-- <div class="index_footer">
      <div class="container index_footer_content">
        <div class="index_footer_left">
          <p>
            友情链接：<span style="margin: 0 10px">
              <a href="https://www.mohrss.gov.cn/" target="blank">中华人民共和国人社部</a>
            </span>
            <span style="margin: 0 10px">
              <a href="https://www.acftu.org/" target="blank">中华全国总工会</a>
            </span>
            <span style="margin: 0 10px">
              <a href="https://www.miit.gov.cn/"
              target="blank"
                >中华人民共和国工业和信息化部</a
              >
            </span>
            <span style="margin: 0 10px">
              <a href="https://chrm.mohrss.gov.cn/" target="blank">中国人力资源市场网</a>
            </span>
          </p>
          <p>技术&运营支持：<span>中工科创</span></p>

         
                    <p>
            售前咨询电话:
            <span style="margin-right: 20px">18310380442</span>售后服务电话:
            <span>13717741496</span>
          </p>
          <p>总部地址：<span>北京市东城区首东国际B座4号17层</span></p>
           <p>
            <a href="https://beian.miit.gov.cn/"
              >Copyright©2015-2023 kskstudy.com All Rights Reseverd</a
            >
          </p>
        </div>

        <div class="index_footer_right">
          <div class="index_footer_img_title">联系我们</div>
          <div class="index_footer_img">
            <div>
              <img src="../assets/images/footer_wx.png"/>
              <div style="font-size: 12px; color: #bcc2cf; margin-top: 10px">
                微信公众号
              </div>
            </div>

            <div>
              <img src="../assets/images/footer_qudao.png"/>
              <div style="font-size: 12px; color: #bcc2cf; margin-top: 10px">
                企业客服微信
              </div>
            </div>
          </div>

          <div class="footer_num">
            销售部客服电话：18310380442 <span>运营部客服电话：13717741496</span>
          </div>
        </div>
      </div>
    </div> -->
    <RegiSter :dialogVisible="dialogVisible" @changeCut="changeCut"></RegiSter>

    <!-- 固定定位 联系我们 -->
    <div class="fixed_right" v-if="height > 400" @click="contactUs">
      <img src="../assets/images/home_service.png"/>
      <div class="fixed_right_text">
        <div>联</div>
        <div>系</div>
        <div>我</div>
        <div>们</div>
      </div>
      <div class="fixed_right_go_top" @click="goTop" v-if="height > 400">
        <img src="../assets/images/home_go_top.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import RegiSter from "@/components/Register.vue";
export default {
  name: "Index",
  components: {
    RegiSter: RegiSter,
  },
  data() {
    return {
      TopStyle: "首页", //首页菜单的样式
      dialogVisible: false,
      //首页菜单
      MenuList: [
        { name: "首页", url: "/" },
        { name: "课程商城", url: "http://www.kskstudy.com" },
        { name: "热门活动", url: "/activityList" },
        // { name: "师资力量", url: "/TeacherList" },
        { name: "成功案例", url: "/CustomerCase" },
        { name: "城市合伙人", url: "https://mp.weixin.qq.com/s?__biz=MzU4ODUyMTE3NA==&mid=2247487875&idx=1&sn=616e39ef0f8360c9dc59e277670e1447&chksm=fddada3acaad532ca544363f92c73abbc22d537f1df92414e046d9352eda763b37f6ba9a3a67#rd"},
        { name: "关于我们", url: "/News" }
      ],
      height: 0,
      imgIndex:0,
      codeImgState:false,
    };
  },
  methods: {
    //菜单激活样式
    CutStyle(val) {
      this.TopStyle = val.name;
      if(val.url.includes('http')){
        window.open(val.url);
      }else{
        this.$router.push(val.url);
      }
    },
    SwitchStatus() {
      this.dialogVisible = true;
    },
    changeCut(res) {
      this.dialogVisible = res;
    },
    goTop() {
      // document.documentElement.scrollTop = 0;
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        700
      );
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.height = scrollTop;
    },
    //联系我们
    contactUs() {
      const h = this.$createElement;
      this.$msgbox({
        title: '',
        showClose: true,
        customClass: 'contact-us-msgbox',
        message: h('img', {
          style: {width: "1240px"},
          attrs:{src: require('@/assets/images/contact-us2.png')}
        }),
        showConfirmButton: false
      })
    },
    showCodeImg(num){
      this.codeImgState = true
      this.imgIndex = num
    },
    
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    path() {
      return this.$store.getters.path;
    },
  },
};
</script>

<style   lang='less'>
//头部菜单列样式
.index_topmenu {
  position: fixed;
  top: 0;
  width: 100%;
  height: 85px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(161, 210, 254, 0.2);
  z-index: 9;
  .top_menu {
    width: 1440px;
    height: 100%;
    overflow: hidden;
    line-height: 80px;
    .leftlogo {
      float: left;
      width: 200px;
      margin: 7px 10px 0 0;
      cursor: pointer;
    }
    .left_title {
      float: left;
      width: 270px;
      height: 100%;
      font-size: 18px;
      color: #254ed4;
      display: flex;
      align-items: center;
    }
    .right_menu {
      float: right;
      div {
        float: left;
        margin-right: 40px;
        font-size: 18px;
        color: #666666;
        cursor: pointer;
      }
      .index_btn {
        background-color: #254ed4;
        color: #fff;
        padding:8px 20px !important;
        font-size: 18px;
        box-shadow: 0px 0px 4px rgba(20, 53, 193, 0.26);
      }
    }
  }
  //激活菜单的样式
  .active_right_menu {
    color: #1435c1 !important;
    border-bottom: 3px solid #254ed4 !important;
  }
}
.index_topmenu_active{
  position: fixed;
  top: 0;
  width: 100%;
  height: 85px;
  z-index: 999;
   .top_menu {
    width: 1440px;
    height: 100%;
    overflow: hidden;
    line-height: 80px;
    .leftlogo {
      float: left;
      width: 200px;
      margin: 7px 10px 0 0;
      cursor: pointer;
    }
    .left_title {
      float: left;
      width: 270px;
      height: 21px;
      font-size: 18px;
      color: #fff;
    }
    .right_menu {
      float: right;
      div {
        float: left;
        margin-right: 40px;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
      }
      .index_btn {
        padding:8px 20px !important;
        color: #fff;
        background:rgba(255,255,255,0.2);
        box-shadow: 0px 0px 4px rgba(20, 53, 193, 0.26);
      }
    }
    .active_right_menu {
      color: #fff !important;
      border-bottom: 3px solid #fff !important;
    }
  }
}



//底部
.index_footer {
  width: 100%;
  height: 300px;
  background: #292c33;
  box-shadow: 4px 0px 6px rgba(161, 210, 254, 0.2);
  border-radius: 100px 0px 0px 0px;
  color: #fff;
  padding-top: 68px;
  box-sizing: border-box;

  .index_footer_content {
    display: flex;
    justify-content: space-between;
    .index_footer_left {
      p {
        font-size: 14px;
        color: #bcc2cf;
        margin: 20px 0;
        &:first-child{
          margin: 0 0 40px 0;
        }
      }
    }
    .index_footer_right {
      .index_footer_img_title {
        font-size: 14px;
        font-weight: bold;
        color: #bcc2cf;
        margin-bottom: 20px;
      }
      .index_footer_img {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        img {
          height: 126px;
          margin-bottom: 10px;
        }
        div:first-child {
          margin-right: 50px;
        }
      }
      .footer_num {
        font-size: 12px;
        line-height: 0px;
        color: #bcc2cf;
        margin-top: 50px;
      }
    }
  }
}
.contact-us-msgbox{
  width: auto !important;
  background-color: initial !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  .el-message-box__header{
    padding: 0 !important;
    z-index: 2 !important;
  }
  .el-icon-close:before{
    color: #fff;
  }
}

// 底部
.footer_box{
  height: 469px;
  background: #292C33;
  border-radius: 76px 0px 0px 0px;
  overflow: hidden;
  ul,li{
    list-style: none;
  }
  .foot_content{
    width: 1100px;
    margin: 0 auto;
    .list_cont{
      display: flex;
      margin-top: 73px;
      .left_logo_box{
        margin-right: 116px;
        p.logoImg{
          width: 153px;
          height: 28px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        p.text{
          color: #fff;
          font-size: 14px;
          margin-top: 15px;
        }
      }
      .link_list{
        display: flex;
        flex: 1;
        justify-content: space-between;
        ul{
          // min-width: 107px;
          // margin-right:84px ;
          li.title{
            font-size: 14px;
            color: #FFFFFF;
            cursor: text;
          }
          li{
            margin-bottom: 16px;
            font-size: 11px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #BCC2CF;
            cursor: pointer;
            
          }
          .hover_item{
            // position: relative;
            // .show_code_box{
            //   position: absolute;
            //   left: 20px;
            //   top: 20px;
            //   display: none;
            //   z-index: 9;
            //   img{
            //     width: 80px;
            //     height: 80px;
            //   }
            // }
          }
          // .hover_item:hover{
          //   .show_code_box{
          //     display: block;
          //   }
          // }
        }
        ul.about_us{
          img{
            width: 71px;
            height: 71px;
          }
        }
      }
    }
    .link_box{
      border-top: 1px solid #BCC2CF;
      border-bottom: 1px solid #BCC2CF;
      display: flex;
      padding: 20px 0;
      margin-top: 47px;
      align-items: center;
      p.text{
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 59px;
      }
      ul{
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        li{
          font-size: 11px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #BCC2CF;
          margin-right: 20px;
          a{
             color: #BCC2CF;
          }
        }
      }
    }
    .text_box{
      margin-top: 30px;
      font-size: 11px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #BCC2CF;
    }
  }
}

// 二维码弹出框
.show_code_box{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.2);
  z-index: 999;
  .img_box{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 400px;
    height: 300px;
    background: #fff;
    border-radius: 10px;
    p.close{
      text-align: right;
      font-size: 30px;
      margin-right: 20px;
      cursor: pointer;
    }
    p.img{
      width: 200px;
      margin: 20px auto 0;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>