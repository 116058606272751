<template>
  <div class="container_box">
    <div class="content_warp">
      <div class="banner_cont">
        <p class="img"><a href="https://www.wenjuan.com/s/I3Ajye5/#" target="_blank" rel="noopener noreferrer"><img src="@/assets/active/banner.png" alt="" /></a></p>
      </div>
      <div class="btn_list">
        <p class="title">方向</p>
        <ul>
          <li  @click="changeBtn('')" :class="activeIndex==''?'active':''">全部</li>
          <li v-for="(item) in categoryList" @click="changeBtn(item.id)" :class="activeIndex==item.id?'active':''" :key="item.id">{{item.title}}</li>
        </ul>
      </div>
      <div class="list_box">
        <ul>
          <li v-for="item in activeList" :key="item.id" @click="goDetail(item.id)">
            <p class="img">
              <img v-if="item.image" :src="item.image" alt="">
              <img v-else src="@/assets/active/img.png" alt="" />
            </p>
            <div class="item_cont">
              <p class="title overflow_e">
                {{item.title}}
              </p>
              <p class="info overflow_e">
                 {{item.desc}}
              </p>
              <div class="icon flex">
                <p class="flex">
                  <img src="@/assets/active/icon2.png" alt="" />
                  <span>活动形式：{{item.way_text}}</span>
                </p>
                <!-- <p  class="flex">
                  <img src="@/assets/active/icon1.png" alt="" />
                  <span></span>
                  <span>{{item.category_title}}</span>
                </p> -->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Empty v-if="activeList.length <= 0" />

      <page-nation
      class="page_nation"
        v-if="num > 0"
        :num="num"
        :limit="limit"
        @inputNum='inputNum'
        @getNew="getNew"
      ></page-nation>
  </div>
</template>
<script>
import pageNation from "../../components/page_input.vue";
import Empty from "../../components/empty.vue";

import {activityCategory, activityList} from '@/API/api'
export default { 
  name:'active_box',
  components: {
    pageNation,
    Empty,
  },
  data() {
    return {
      // 分页信息
      num: 0, //数据总个数
      limit: 12, //一页显示几个
      nowPage: 1, //当前页码
      activeList:[{}],
      activeIndex:'',
      categoryList:[],
    };
  },
  mounted(){
     document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.getCategory()
    this.getList()
  },
  methods: {
    getCategory(){
      activityCategory().then(res=>{
        console.log(res,'lll')
        if(res.code==0){
          this.categoryList = res.data
        }
        
      })
    },
    getList(){
      let data={
        category_id:this.activeIndex,
        per_page:this.limit,
        page:this.nowPage,
      }
      activityList(data).then(res=>{
        console.log(res)
        if(res.code==0){
          this.activeList = res.data.data
          this.num = res.data.total
        }
      })
    },
    goDetail(id){
      this.$router.push('/activityDetail?id='+id)
    },
    changeBtn(index){
        this.activeIndex = index
        this.nowPage=1
        this.getList()
    },

     inputNum(val){
      console.log(val)
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getList();
    },
  },
};
</script>
<style scoped lang='less'>
.container_box{
    background: #fff;
    .content_warp{
        width: 1090px;
        margin: 123px auto 0;
        .banner_cont{
            width: 100%;
            img{
                width: 100%;
                height: auto;
            }
        }
        .btn_list{
            display: flex;
            margin-top: 61px;
            p.title{
                font-size: 16px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #666666;
                margin-right: 35px;
                line-height: 24px;
            }
            ul{
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                li{
                    font-size: 12px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    line-height: 24px;
                    background: #F3F3F3;
                    border-radius: 12px 12px 12px 12px;
                    padding: 0 15px;
                    margin-right: 35px;
                    margin-bottom: 15px;
                    cursor: pointer;
                }
                li.active{
                    background: #0065DF;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
        }
        .list_box{
            margin-top: 68px;
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                   width: 343px;
                    height: 310px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 6px 1px rgba(20,53,193,0.16);
                    border-radius: 8px 8px 8px 8px;
                    margin-right: 30px;
                    margin-bottom: 30px;
                    cursor: pointer;
                    p.img{
                         width: 100%;
                         height: 192px;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 8px  8px 0 0;
                        }
                    }
                    div.item_cont{
                        margin:7px 9px 0;
                        p.title{
                            margin-left: 14px;
                            font-size: 16px;
                            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            height: 32px;
                        }
                        p.info{
                          font-size: 12px;
                          height: 32px;
                            margin-left: 14px;
                          color: #666;
                        }
                        div.icon{
                            justify-content: space-between;
                            margin-top: 11px;
                            img{
                                width: 23px;
                                height: 23px;
                            }
                            p{
                                span{
                                    font-size: 14px;
                                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                                    font-weight: 400;
                                    color: #999999;
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
                li:nth-child(3n){
                    margin-right: 0;
                }
            }
        }
        
    }
    .page_nation{
        margin-top: 73px;
        margin-bottom: 92px;
    }
}
</style>