<template>
  <div class="news">
    <div class="news_top">
      <!-- <img src="../assets/images/index_top_bgc.png" alt=""> -->
      <div class="news_top_title">中工科创</div>
      <div class="news_top_content">
        职业教育培训领域高新技术企业
      </div>
       <div class="news_top_content">
        集线上培训平台建设运营、线下大型教培基地整合运作、职业技能培训全生态解决方案于一体
      </div>
    </div>

    <div class="news_tab">
      <div class="container news_tab_list">
        <div
          v-for="(item, index) in list"
          :key="index"
          @click="TabStyle(item)"
          :class="tabstatus == item.name ? 'active_news_tab' : ''"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="news_content container" v-if="tabstatus == '新闻动态'">
      <div class="news_content_title">新闻动态</div>
      <div class="news_content_detail">NEWS</div>
      <div class="newx_list" v-for="(item, index) in NewsList" :key="index" @click="GoNewsDetail(item)">
        <!-- <img src="../assets/images/index_case.png" alt="" /> -->

        <img
          :src="item.image"
          alt=""
        />
        <div class="news_list_right">
          <div class="news_list_right_title">
            <span class="title">{{ item.title }}</span>
            <span class="time">{{ item.created_at }}</span>
          </div>
          <div class="news_list_right_content">
            {{ item.desc }}
          </div>
          <div class="news_list_right_more">查看更多 >></div>
        </div>
      </div>

      <div class="news_btns container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="Page.page"
          :page-size="Page.per_page"
          layout=" prev, pager, next,sizes, jumper,total"
          :total="Total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsList,getNewsDetail } from "@/API/api";
export default {
  data() {
    return {
      list: [
        { name: "公司简介", url: "/CompanyProfile" },
        { name: "新闻动态", url: "/News" },
        { name: "发展历程", url: "/History" },
        { name: "加入我们", url: "/Join" },
      ],
      tabstatus: "新闻动态",
      Page: {
        page: 1,
        per_page: 10,
      },
      Total: 0,
      NewsList: [],
    };
  },
  methods: {
    TabStyle(val) {
      this.tabstatus = val.name;
      this.$router.push(val.url);
    },
    GetNewsList() {
      getNewsList(this.Page).then((res) => {
        if (res.code == 0) {
          this.NewsList = res.data.data;
          this.Total = res.data.total;
        }
      });
    },
    //新闻详情
    GoNewsDetail(val){
      this.$router.push({
        path:'NewsDetail',
        query:{
          id:val.id
        }
      })

    },
    //分页切换
    handleSizeChange(val) {
      this.Page.per_page = val;
      this.GetNewsList();
    },
    handleCurrentChange(val) {
      this.Page.page = val;
      this.GetNewsList();
    },
  },
  mounted() {
    this.GetNewsList();
  },
};
</script>

<style scoped lang='less'>
//新闻的头部
.news_top {
  width: 100%;
  height: 388px;
  background: url("../assets/images/kehu_bgc.png") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 157px;
  box-sizing: border-box;
  .news_top_title {
    height: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 42px;
  }
  .news_top_content {
    margin: 0 auto;
    font-size: 20px;
    line-height: 40px;
    color: #fff;
  }
}
//切换菜单
.news_tab {
  width: 100%;
  height: 74px;
  background: #ccc;
  .news_tab_list {
    height: 100%;
    display: flex;
    opacity: 1 !important;
    div {
      width: 25%;
      height: 100%;
      line-height: 74px;
      text-align: center;
      color: #222;
      cursor: pointer;
      font-size: 20px;
    }
  }
}
//新闻模块
.news_content {
  text-align: center;
  margin-top: 89px;
  .news_content_title {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 7px;
  }
  .news_content_detail {
    font-size: 30px;
    color: #999999;
    margin-bottom: 23px;
  }
  .newx_list {
    width: 100%;
    height: 281px;
    border: 3px solid #ffffff;
    background: linear-gradient(152deg, #f4f6f9 0%, #f9fafc 100%);
    box-shadow: 0px 0px 6px rgba(20, 53, 193, 0.1);
    border-radius: 4px;
    margin-bottom: 17px;
    display: flex;
    padding: 54px 28px 56px 30px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 315px;
      height: 197;
    }
    .news_list_right {
      width: 100%;
      margin-left: 30px;
      .news_list_right_title {
        overflow: hidden;
        color: #333333;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        span{
          display: block;
          text-align: left;
        }
        span.title{
          flex:1;
        }
        span.time{
          margin-left:30px ;
        }
      }
      .news_list_right_content {
        text-align: left;
        font-size: 14px;
        margin-top: 22px;
      }
      .news_list_right_more {
        margin-top: 61px;
        text-align: left;
        cursor: pointer;
        font-size: 14px;
        color: #254ed4;
      }
    }
  }
  .news_btns {
    margin: 100px auto 150px;
    text-align: center;
  }
}

//菜单激活的样式
.active_news_tab {
  background-color: #fff;
  font-weight: bold;
  font-size: 20px;
  color: #254ed4 !important;
}
</style>