import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    CurrentPath:''
  },
  mutations: {
    SetPath(state,data){
      state.CurrentPath=data
    } 
  },
  actions: {
    SetSyncPath(){

    }
  },
  modules: {
  },
  getters:{
    path:(state)=>state.CurrentPath
  }
})
