import  request from '@/network/request.js'
import  request1 from '@/network/request1.js'


//新闻列表
export const getNewsList= async function (params){
    let result =request.get('/open/news/index',{params})
    return result
}

//新闻详情
export const getNewsDetail= async function (params){
    let result =request.get('/open/news/detail',{params})
    return result

}
// 发送短信验证码
export const SendSmsCode= async function (params){
    let result = await request.get('/open/user/sendSmsCode',{params})
    return result
}

// 免费体验用户
export const RegisterUser= async function (params){
    let result =request.get('/open/user/registerUser',{params})
    return result
}

// 公司简介
export const getCompanyInfo= async function (params){
    let result =request.get('/open/company/index',{params})
    return result
}

// 讲师列表
export const getTeacherList= async function (params){
    let result =request1.get('middle/teacher/openList',{params})
    return result
}

// 讲师分类
export const getTeacherIndustry= async function (params){
    let result =request1.get('middle/teacher/industry',{params})
    return result
}

// 活动分类
export const activityCategory= async function (params){
    let result =request1.get('api/activity/category',{params})
    return result
}

// 活动列表
export const activityList= async function (params){
    let result =request1.get('api/activity/list',{params})
    return result
}
// 活动详情
export const activityDetail= async function (params){
    let result =request1.get('api/activity/detail',{params})
    return result
}

// 推荐活动列表
export const activityRecommend= async function (params){
    let result =request1.get('api/activity/recommend',{params})
    return result
}

// 活动 -立即报名
export const activityForm= async function (data){
    let result =request1.post('api/activity/activityForm',data)
    return result
}

