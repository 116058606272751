<template>
  <div class="news">
    <div class="news_top">
      <!-- <img src="../assets/images/index_top_bgc.png" alt=""> -->
      <div class="news_top_title">中工科创</div>
      <div class="news_top_content">
        职业教育培训领域高新技术企业
      </div>
       <div class="news_top_content">
        集线上培训平台建设运营、线下大型教培基地整合运作、职业技能培训全生态解决方案于一体
      </div>
    </div>

    <div class="news_tab">
      <div class="container news_tab_list">
        <div
          v-for="(item, index) in list"
          :key="index"
          @click="TabStyle(item)"
          :class="tabstatus == item.name ? 'active_news_tab' : ''"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="join_us container" v-if="tabstatus == '加入我们'">
      <div class="join_title">加入我们</div>
      <div class="join_subtitle">JOIN US</div>

      <div class="join_us_content">
        <img src="../assets/images/join_us_1.png" alt="" />
        <div class="join_us_content_right" style="margin-left:20px">
          <div class="join_us_componey">
            <span style="font-weight: 700">企业愿景：</span>
            <span>技能报国、实业报国、制造强国</span>
          </div>
          <div class="join_us_componey">
            <span style="font-weight: 700">企业使命：</span>
            <span>助力亿万技术人才价值实现</span>
          </div>
          <div class="join_us_componey" style="margin: 0; line-height: 25px">
            <span style="font-weight: 700">企业目标：</span>
            <span style="width: 340px"
              >打造高技能人才技能成长体系，引领建设全国 职工培训生态</span
            >
          </div>
        </div>
      </div>

      <div class="join_us_content">
        <div class="left">
          <div style="line-height: 25px">
            <span style="font-weight: 700"> 企业战略：</span>
            围绕中央关于产品工人队伍建设改革和职业技能人才培训和先进制企业技术服务的互联网公司，深入开展成果交易孵化等领域，打造服务企业技术创新生命周期。
          </div>
          <div class="join_us_content_left_btn" @click="JoinUs">招聘岗位</div>
        </div>
        <img src="../assets/images/join_us_2.png" alt="" />
      </div>

      <div class="join_us_content" style="height: 414px">
        <img
          src="../assets/images/join_us_3.png"
          alt=""
          style="width: 100%; height: 100%"
        />
      </div>
    </div>

    <el-dialog :visible.sync="Module" width="30%" :before-close="handleClose">
      <div slot="title">招兵买马</div>
      <!-- <div>微信扫描BOSS直聘小程序码</div> -->
      <div style="margin: 20px 0;text-align: center;">
        <img src="../assets/images/componey_img.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { name: "公司简介", url: "/CompanyProfile" },
        { name: "新闻动态", url: "/News" },
        { name: "发展历程", url: "/History" },
        { name: "加入我们", url: "/Join" },
      ],
      tabstatus: "加入我们",
      Module: false,
    };
  },
  methods: {
    TabStyle(val) {
      this.tabstatus = val.name;
      this.$router.push(val.url);
    },
    JoinUs() {
      this.Module = true;
    },
    handleClose() {
      this.Module = false;
    },
  },
  mounted() {},
};
</script>

<style scoped lang='less'>
//新闻的头部
.news_top {
  width: 100%;
  height: 388px;
  background: url("../assets/images/kehu_bgc.png") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 157px;
  box-sizing: border-box;
  .news_top_title {
    height: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 42px;
  }
  .news_top_content {
    margin: 0 auto;
    font-size: 20px;
    line-height: 40px;
    color: #fff;
  }
}
//切换菜单
.news_tab {
  width: 100%;
  height: 74px;
  background: #ccc;
  .news_tab_list {
    height: 100%;
    display: flex;
    opacity: 1 !important;
    div {
      width: 25%;
      height: 100%;
      line-height: 74px;
      text-align: center;
      color: #222;
      cursor: pointer;
      font-size: 20px;
    }
  }
}

//加入我们
.join_us {
  margin: 0 auto 20px;
  background: #ffffff;
  padding: 89px 79px 80px 57px;
  margin-bottom: 8px;
  box-sizing: border-box;
  .join_title {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .join_subtitle {
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    color: #999999;
    margin: 10px 0 65px 0;
  }
  .join_us_content {
    display: flex;
    width: 100%;
    height: 326px;
    margin-bottom: 20px;
    justify-content: space-between;
    img {
      width: 50%;
      height: 326px;
      
    }
    .join_us_content_right {
      width: 50%;
      padding-top: 60px;
      box-sizing: border-box;
      .join_us_componey {
        display: flex;
        line-height: 55px;
      }
    }
    .left {
      width: 500px;
      padding: 72px 20px 0 20px;
      box-sizing: border-box;
      .join_us_content_left_btn {
        width: 100px;
        height: 30px;
        border: 1px solid #464ff8;
        background: #254ed4;
        box-shadow: 0px 0px 4px rgba(20, 53, 193, 0.26);
        border-radius: 4px;
        color: #fcfcfe;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        cursor: pointer;
        margin-top: 87px;
      }
    }
  }
}

//菜单激活的样式
.active_news_tab {
  background-color: #fff;
  font-weight: bold;
  font-size: 20px;
  color: #1453c1 !important;
}
</style>