<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal='false'
    >
      <div class="title"><span style="color: #254ed4">KSK·</span> 开放平台</div>
      <div class="title1">免费体验</div>
      <div>
        <el-steps :active="1" align-center>
          <el-step title="提交信息"></el-step>
          <el-step title="提交成功"></el-step>
        </el-steps>
      </div>

      <div class="form">
        <el-form
          :model="formItem"
          :rules="rules"
          ref="formItem"
          label-width="70px"
          width="80%"
        >
          <el-form-item label="企业" prop="company">
            <el-input
              v-model="formItem.company"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="负责人" prop="name">
            <el-input
              v-model="formItem.name"
              placeholder="请输入负责人"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="formItem.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input
              v-model="formItem.code"
              style="width: 60%; float: left"
              placeholder="请输入验证码"
              :disabled="input_disabled"
            ></el-input>
            <el-button
              class="btn_right"
              @click="SendCode"
              :disabled="disabled"
              >{{ text }}</el-button
            >
          </el-form-item>
          <el-form-item label="">
            <el-button class="btn" @click="register('formItem')"
              >免费体验</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { SendSmsCode, RegisterUser } from "@/API/api.js";
export default {
  props: {
    dialogVisible: Boolean,
  },
  data() {
    return {
      formItem: {
        company: "",
        name: "",
        phone: "",
        code: "",
      },
      rules: {
        company: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入负责人", trigger: "blur" }],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      text: "获取验证码",
      disabled: false,
      input_disabled: true,
      dsq: "",
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.$emit("changeCut", false);
    },
    //发送验证码
    SendCode() {
      this.$refs.formItem.validateField("phone", (isvalid) => {
        if (isvalid) {
          // this.$message.error(isvalid);
        } else {
          SendSmsCode({
            phone: this.formItem.phone,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
          let time = 60;
          this.dsq = setInterval(() => {
            time--;
            this.text = time + "s后获取";
            this.disabled = true;
            this.input_disabled = false;
            if (time <= 1) {
              this.text = "获取验证码";
              this.disabled = false;
              clearInterval(this.dsq);
            }
          }, 1000);
        }
      });
    },
    //注册
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          RegisterUser(this.formItem).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.dialogVisible = false;
              this.$emit("changeCut", false);
              clearInterval(this.dsq);
              for (var key in this.formItem) {
                this.formItem[key] = "";
              }
            }
          });
        } else {
          // this.$message.error("请填写必填项");

          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.title {
  text-align: center;
  margin: 20px 0;
  font-size: 30px;
}
.form {
  padding: 0 30px;
  margin: 20px 0 50px 0;
}
.title1 {
  text-align: center;
  font-size: 14px;
  color: #254ed4;
}
.btn_right {
  float: right;
  width: 37%;
  background-color: #254ed4;
  color: #fff;
}
.btn {
  width: 100%;
  background-color: #254ed4;
  color: #fff;
}
</style>