<template>
  <div class="news">
    <div class="news_top">
      <!-- <img src="../assets/images/index_top_bgc.png" alt=""> -->
      <div class="news_top_title">中工科创</div>
      <div class="news_top_content">
        职业教育培训领域高新技术企业
      </div>
      <div class="news_top_content">
        集线上培训平台建设运营、线下大型教培基地整合运作、职业技能培训全生态解决方案于一体
      </div>
    </div>

    <div class="news_tab">
      <div class="container news_tab_list">
        <div
          v-for="(item, index) in list"
          :key="index"
          @click="TabStyle(item)"
          :class="tabstatus == item.name ? 'active_news_tab' : ''"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="componey container" v-if="tabstatus == '公司简介'">
      <div class="componey_detail">
        <div class="componey_detail_list">
          <div>10余</div>
          <div>课程模块</div>
        </div>
        <div class="componey_detail_list">
          <div>30+</div>
          <div>行业领域</div>
        </div>
        <div class="componey_detail_list">
          <div>200+</div>
          <div>工种岗位</div>
        </div>
        <div class="componey_detail_list">
          <div>5W+</div>
          <div>精品课程</div>
        </div>
        <div class="componey_detail_list">
          <div>100+</div>
          <div>合作机构</div>
        </div>
        <div class="componey_detail_list">
          <div>60+</div>
          <div style="font-size: 20px; color: #333333">专业技术委员会</div>
          <div>和工匠学院支撑</div>
        </div>
        <div class="componey_detail_list">
          <div>500+</div>
          <div>讲师阵容</div>
        </div>
      </div>

      <div class="compony_bottom">
        <div class="compony_bottom_title">公司简介</div>
        <div class="compony_bottom_logo">INTRODUCE</div>
       <p v-html="info"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanyInfo } from "@/API/api";
export default {
  data() {
    return {
      list: [
        { name: "公司简介", url: "/CompanyProfile" },
        { name: "新闻动态", url: "/News" },
        { name: "发展历程", url: "/History" },
        { name: "加入我们", url: "/Join" },
      ],
      tabstatus: "公司简介",
      info:{}
    };
  },
  methods: {
    TabStyle(val) {
      this.tabstatus = val.name;
      this.$router.push(val.url);
    },
    GetInfo() {
      getCompanyInfo().then((res) => {
        if(res.code==0){
          this.info=res.data
        }
      });
    },
  },
  mounted() {
    this.GetInfo();
  },
};
</script>

<style scoped lang='less'>
//新闻的头部
.news_top {
  width: 100%;
  height: 388px;
  background: url("../assets/images/kehu_bgc.png") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 157px;
  box-sizing: border-box;
  .news_top_title {
    height: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 42px;
  }
  .news_top_content {
    margin: 0 auto;
    line-height: 40px;
    font-size: 20px;
    color: #fff;
  }
}
//切换菜单
.news_tab {
  width: 100%;
  height: 74px;
  background: #ccc;
  .news_tab_list {
    height: 100%;
    display: flex;
    opacity: 1 !important;
    div {
      width: 25%;
      height: 100%;
      line-height: 74px;
      text-align: center;
      color: #222;
      cursor: pointer;
      font-size: 20px;
    }
  }
}

//公司简介
.componey {
  .componey_detail {
    height: 223px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(161, 210, 254, 0.1);
    margin-bottom: 79px;
    padding: 60px 66px 30px 63px;
    box-sizing: border-box;
    display: flex;
    .componey_detail_list {
      width: 100%;
      margin: 0 5px;
      text-align: center;
      div:first-child {
        height: 40px;
        font-size: 30px;
        font-weight: bold;
        color: #1435c1;
        margin-bottom: 12px;
      }
      div:last-child {
        font-size: 20px;
        color: #333333;
      }
    }
  }
  .compony_bottom {
    height: 552px;
    border: 3px solid #ffffff;
    background: linear-gradient(46deg, #fefefe 0%, #f4f7fc 100%);
    box-shadow: 3px 4px 10px rgba(20, 53, 193, 0.14);
    border-radius: 4px;
    padding: 54px 84px 62px 84px;
    box-sizing: border-box;
    margin-bottom: 34px;
    .compony_bottom_title {
      height: 40px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-bottom: 10px;
    }
    .compony_bottom_logo {
      font-size: 30px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin: 42px;
    }
    p{
      font-size: 16px;
      color: #333333;
      line-height: 25px;
      margin: 10px 0;
    }
  }
}

//菜单激活的样式
.active_news_tab {
  background-color: #fff;
  font-weight: bold;
  font-size: 20px;
  color: #254ed4 !important;
}
</style>