<template>
  <div class="teacher_list">
    <div class="teacher_box">
      <div class="teacher_list">
        <ul class="flex" v-if="teacherTopList">
          <li
            v-for="item in teacherTopList.slice(0, 5)"
            :key="item.id"
            @click="contactUs"
          >
            <p class="img">
              <!-- <img :src="item.avatar" alt="" /> -->
              <img v-if="item.avatar" :src="item.avatar" alt="" srcset="" />
              <img v-else src="@/assets/teacher/teacher_info.png" alt="" srcset="" />
            </p>
            <div class="name">
              <span>{{ item.name }}</span>
              <!-- <p>{{ item.level }}</p> -->
            </div>
            <div class="mask"></div>
          </li>
        </ul>
      </div>
      <div class="tab_list">
        <ul class="">
          <p>行业</p>
          <div class="flex" :class="{'stow': !industryTypeOpen}">
            <li
              :class="index == tabIndex ? 'active' : ''"
              v-for="(item, index) in tabList"
              :key="index"
              @click="tabClick(index)"
            >
              {{ item }}
            </li>
          </div>
        </ul>
        <div class="tab-list-open" :class="{'open':industryTypeOpen}" @click="industryTypeOpen=!industryTypeOpen">{{industryTypeOpen ? '收起' : '更多'}}</div>
        <!-- 工种 -->
        <ul class="">
          <p>工种</p>
          <div class="flex" :class="{'stow': !workTypeOpen}">
            <li
              :class="item.id == workTypeId ? 'active' : ''"
              v-for="item in workTypeList"
              :key="item.id"
              @click="workTypeClick(item.id)"
            >
              {{ item.title }}
            </li>
          </div>
        </ul>
        <div class="tab-list-open" :class="{'open':workTypeOpen}" @click="workTypeOpen=!workTypeOpen">{{workTypeOpen ? '收起' : '更多'}}</div>

        <!-- <ul>
          <p>合作级别</p>
          <div class="flex">
            <li
              :class="index ==  levelIndex? 'active' : ''"
              v-for="(item, index) in levelList"
              :key="index"
              @click="levelClick(index)"
            >
              {{ item }}
            </li>
          </div>
        </ul> -->
        <!-- <ul>
          <p>是否可定制教学</p>
          <div class="flex">
            <li
              :class="item.id == identIndex ? 'active' : ''"
              v-for="(item) in teacherIdent"
              :key="item.id"
              @click="identClick(item.id)"
            >
              {{ item.name }}
            </li>
          </div>
        </ul> -->
      </div>

      <div class="list">
        <ul>
          <li v-for="item in teacherList" :key="item.id">
            <p class="img">
              <img v-if="item.avatar" :src="item.avatar" alt="" srcset="" />
              <img v-else src="@/assets/teacher/teacher_info.png" alt="" srcset="" />
            </p>
            <div class="right_cont">
              <div class="name flex">
                <span>{{ item.name }}</span>
                <!-- <p>{{ item.level }}</p> -->
              </div>
              <!-- <p class="tips" v-if="item.teacher_tags.length > 0">
                <span v-for="tip in item.teacher_tags" :key="tip">{{
                  tip
                }}</span>
              </p> -->
              <div class="title">
                {{ item.introduce }}
              </div>
              <!-- <div class="play flex">
                <p class="img_icon">
                  <img src="@/assets/teacher/play_icon.png" alt="" />
                </p>
                <span>如果有课程调取一条最新课程，没有则不调取</span>
              </div> -->
              <div class="right_btn flex cursor" @click="contactUs">
                <span>点击查看</span>
                <img src="@/assets/teacher/goin.png" alt="" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Empty v-if="teacherList.length <= 0" />

      <page-nation
        v-if="num > 0"
        :num="num"
        :limit="limit"
        @inputNum='inputNum'
        @getNew="getNew"
      ></page-nation>
      
    </div>
  </div>
</template>
<script>
import pageNation from "../../components/page_input.vue";
import Empty from "../../components/empty.vue";
import { getTeacherList,getTeacherIndustry } from "@/API/api";

export default {
  name: "pc-teacher_list",
  components: {
    pageNation,
    Empty,
  },
  data() {
    return {
      // 分页信息
      num: 0, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
      teacherList: [],
      teacherTopList: [],
      tabList: [],
      levelList: [],
      tabIndex: "",
      levelIndex: "",
      identIndex:'',
      teacherIdent:[
        // {name:'全部',id:'3'},
        {name:'是',id:'1'},
        {name:'否',id:'0'}
      ],
      industryTypeOpen: false,  //行业展开收起
      workTypeOpen: false,  //工种展开收起
      workTypeList:[],//工种列表
      workTypeId:'',
    };
  },
  mounted() {
     document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
     this.identIndex = this.$route.query.num?this.$route.query.num:''
    this.getIndustryList();
    this.getResultsList();
    this.getTopList();
   
    
  },
  methods: {
    inputNum(val){
      console.log(val)
    },
    getTopList() {
      let data={
        level:this.levelIndex,
        industry:this.tabIndex
      }
      getTeacherList(data).then(res=>{
        this.teacherTopList = res.data.list;
      })
    },
    getResultsList() {


      let data={
        level:this.levelIndex,
        industry:this.tabIndex,
        work_type:this.workTypeId,
        page:this.nowPage,
        pre_page:10,
      }
      getTeacherList(data).then(res=>{
          this.teacherList = res.data.list;
          this.num = res.data.total;
      })
    },
    getIndustryList() {
      getTeacherIndustry().then(res=>{
          this.tabList = res.data.industry;
        this.workTypeList = res.data.work_type;

        this.levelList = res.data.level;
      })

    },
    // 选择课程
    tabClick(item) {
      if (item == this.tabIndex) {
        this.tabIndex = "";
      } else {
        this.tabIndex = item;
      }
    this.nowPage=1
      this.getResultsList();
    },
    //选择工种
    workTypeClick(id){
      if (id == this.workTypeId) {
        this.workTypeId = "";
      } else {
        this.workTypeId = id;
      }
    this.nowPage=1

      this.getResultsList();
    },
    // 选择等级
    levelClick(item) {
      if (item == this.levelIndex) {
        this.levelIndex = "";
      } else {
        this.levelIndex = item;
      }
      this.getResultsList();
    },
    // 选择等级
    identClick(item) {
      if (item == this.identIndex) {
        this.identIndex = "";
      } else {
        this.identIndex = item;
      }
      this.getResultsList();
    },
    // 跳转到详情
    goDetail(item) {
      this.$router.push({
        path: "/teacherInfo/"+item.id,
        // query: {
        //   id: item.id,
        // },
      });
    },
    //翻页
    getNew(nowPage) {
      this.nowPage = nowPage;
      this.getResultsList();
    },

    //联系我们
    contactUs() {
      const h = this.$createElement;
      this.$msgbox({
        title: '',
        showClose: true,
        customClass: 'contact-us-msgbox',
        message: h('img', {
          style: {width: "1240px"},
          attrs:{src: require('@/assets/images/contact-us2.png')}
        }),
        showConfirmButton: false
      })
    }
  },
};
</script>

<style lang="less" scoped>
ul,li{
  list-style: none;
}
.teacher_list {
  background: #f9f9f9;
  padding-bottom: 148px;
  margin-top: 50px;
}
.flex {
  display: flex;
  align-items: center;
}
.teacher_box {
  width: 1200px;
  margin: 0px auto;
  padding-top: 16px;
  .teacher_list {
    background: #fff;
    padding-bottom: 0;
    ul {
      li:last-child {
        margin-right: 0;
      }
      li {
        width: 225px;
        height: 297px;
        position: relative;
        margin-right: 24px;
        position: relative;
        .mask {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 60%,
            #000000 99%
          );
          // z-index: 0;
        }
        p.img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;

          }
        }
        div.name {
          position: absolute;
          bottom: 13px;
          left: 17px;
          display: flex;
          z-index: 1;
          span {
            font-size: 15px;
            font-weight: bold;
            color: #ffffff;
            line-height: 18px;
          }
          p {
            // width: ;
            padding: 0 15px;
            height: 18px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 2px 2px 2px 2px;
            text-align: center;
            line-height: 18px;
            margin-left: 5px;
            color: #eda220;
          }
        }
      }
    }
  }
  .tab_list {
    margin-top: 32px;
    min-height: 160px;
    background: #fff;
    overflow: hidden;
    .stow{
      height: 40px;
      overflow: hidden;
    }
    ul {
      margin-top: 37px;
      display: flex;
      div {
        flex-wrap: wrap;
        flex: 1;
      }
      p {
        min-width: 56px;
        height: 21px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-left: 24px;
        margin-right: 30px;
        line-height: 32px;
      }
      li {
        min-width: 82px;
        height: 32px;
        padding: 0 10px;
        background: #eeeeee;
        border-radius: 43px 43px 43px 43px;
        margin-right: 18px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        color: #999999;
        margin-bottom: 15px;
        cursor: pointer;
      }
      li:last-child {
        margin-right: 0;
      }
      li.active {
        background: #0065df;
        color: #fff;
      }
    }
  }
  .list {
    margin-top: 32px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li:nth-child(2n) {
        margin-right: 0;
      }
      li {
        width: 46%;
        height: 252px;
        background: #ffffff;
        box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.14);
        border-radius: 6px 6px 6px 6px;
        padding: 16px;
        display: flex;
        margin-right: 24px;
        margin-bottom: 24px;
        p.img {
          width: 165px;
          height: 220px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;

          }
        }
        .right_cont {
          margin-left: 20px;
          flex: 1;
          position: relative;
          .name {
            span {
              font-size: 16px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #333333;
              line-height: 18px;
            }
            p {
              width: 58px;
              height: 18px;
              background: rgba(255, 195, 11, 0.14);
              border-radius: 2px 2px 2px 2px;
              text-align: center;
              line-height: 18px;
              font-size: 12px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #f5a623;
              margin-left: 15px;
            }
          }
          p.tips {
            margin-top: 12px;
            width: 101px;
            height: 18px;
            background: #ededed;
            border-radius: 2px 2px 2px 2px;
            font-size: 12px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            text-align: center;
            line-height: 18px;
          }
          .title {
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            margin-top: 32px;
            // height: 48px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .play {
            margin-top: 20px;
            .img_icon {
              width: 16px;
              height: 16px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            span {
              font-size: 12px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              margin-left: 10px;
            }
          }
          .right_btn {
            justify-content: flex-end;
            margin-top: 34px;
            position: absolute;
            bottom: 0;
            right: 0;

            span {
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #0065df;
            }
            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
.tab-list-open{
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: grey;
  margin: 0 auto;
  cursor: pointer;
  padding-bottom: 20px;
  &::after{
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: url(~@/assets/teacher/left_icon.png) no-repeat center center/100%;
    transform: rotate(-90deg);
  }
  &.open::after{
    transform: rotate(90deg);
    background-position-x: 1px;
  }
}
.flex_justify {
  justify-content: space-between;
}
.flex_align {
  align-items: center;
}
</style>
