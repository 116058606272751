<template>
  <div class="news">
    <div class="news_top">
      <div class="news_top_title">中工科创</div>


        <div class="news_top_content">
        职业教育培训领域高新技术企业
      </div>
      <div class="news_top_content">
        集线上培训平台建设运营、线下大型教培基地整合运作、职业技能培训全生态解决方案于一体
      </div>
    </div>

    <div class="news_tab">
      <div class="container news_tab_list">
        <div
          v-for="(item, index) in list"
          :key="index"
          @click="TabStyle(item)"
          :class="tabstatus == item.name ? 'active_news_tab' : ''"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="history_dev container" v-if="tabstatus == '发展历程'">
      <div class="history_dev_title">发展历程</div>
      <div class="history_dev_logo">DEVELOPMENT PROCESS</div>
      <el-timeline reverse>
        <el-timeline-item timestamp="2013年3月" placement="top" color="#254ED4">
          <div class="history_dev_module">
            2013年7月，中工科创公司成立，同年承担中华全国总工会主管，全总经济部、中国职工技术协会主办的《中国职工科技报》媒体改革任务。
          </div>
        </el-timeline-item>

        <el-timeline-item timestamp="2016年7月" placement="top" color="#254ED4">
          <div class="history_dev_module">
            《中国职工科技报》停刊。中工科创公司改制完成，创立“中国科技创新网”。
          </div>
        </el-timeline-item>

        <el-timeline-item timestamp="2016年8月" placement="top" color="#254ED4">
          <div class="history_dev_module">
            中国科技创新网联合科技部中国技术市场协会在沈阳共同成立“中国工匠联盟”。
          </div>
        </el-timeline-item>

        <el-timeline-item timestamp="2017年6月" placement="top" color="#254ED4">
          <div class="history_dev_module">
            中国科技创新网当选中国国防邮电职工技术协会理事单位。中工科创公司成为协会的秘书处运营机构，公司总经理任协会副秘书长。
          </div>
        </el-timeline-item>

        <el-timeline-item timestamp="2017年8月" placement="top" color="#254ED4">
          <div class="history_dev_module">
            同年8月，第一家新时代工匠学院在兵器工业集团内蒙古一机集团成立，中工科创作为全权运营机构，承担了22支专业技术委员会队伍建设、24家院区建设、工匠学院狮子体系建设和课程体系开发等工作。2018年11月，全国人大常委会副委员长、中华全国总工会主席王东明同志在中国工会第十七次全国代表大会上的报告中鲜明提出“深化新时代工匠学院建设”
          </div>
        </el-timeline-item>

        <el-timeline-item
          timestamp="2017年12月"
          placement="top"
          color="#254ED4"
        >
          <div class="history_dev_module">
            联合举办首届国防邮电产业职工技术创新成果展洽会，全国总工会、中央军民融合发展委员会办公室、国防科技工业局的领导共同在现场启动了“国防邮电产业职工技术创新程国交易中心”，后更名为全国职工技术创新成果孵化转化服务中心，由中工科创全权运营。
          </div>
        </el-timeline-item>

        <el-timeline-item timestamp="2018年3月" placement="top" color="#254ED4">
          <div class="history_dev_module">
            制造业高技能人才技能培训平台“中工云课堂”上线。2018-2019年，承建湖北、浙江、内蒙等省市区的职工线上学习平台，连续两年成为全国工会互联网+建设工作典型。
          </div>
        </el-timeline-item>

        <el-timeline-item
          timestamp="2018年10月"
          placement="top"
          color="#254ED4"
        >
          <div class="history_dev_module">
            联合举办首届国防邮电产业职工技术创新成果展洽会，全国总工会、中央军民融合发展委员会办公室、国防科技工业局的领导共同在现场启动了“国防邮电产业职工技术创新程国交易中心”，后更名为全国职工技术创新成果孵化转化服务中心，由中工科创全权运营。
          </div>
        </el-timeline-item>

        <el-timeline-item
          timestamp="2019年10月"
          placement="top"
          color="#254ED4"
        >
          <div class="history_dev_module">
            建成KSK职业技能培训平台（kskstudy.com），成为当时国内唯一一家提供SaaS平台+内容+运营+赋能模式的线上线下融合的技能培训服务商。
          </div>
        </el-timeline-item>

        <el-timeline-item timestamp="2020年2月" placement="top" color="#254ED4">
          <div class="history_dev_module">
         2020年2月5日，国家发展改革委、工业和信息化部、人力资源社会保障部、全国总工会联合印发了《关于应对新型冠状病毒感染肺炎疫情 支持鼓励劳动者参与线上职业技能培训的通知》，支持鼓励劳动者参与线上职业技能培训，助力打赢疫情防控阻击战。依托中工科创运营“技能强国—全国产业工人技能学习平台”（skills.kjcxchina.com），对劳动者实行重点课程免费开放。
          </div>
        </el-timeline-item>

        <el-timeline-item timestamp="2020年6月" placement="top" color="#254ED4">
          <div class="history_dev_module">
            平台累计总访问量超亿次，有432.6万人通过平台进行技能学习培训，培训总时长达1112万小时以上；培训平台二期上线。
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { name: "公司简介", url: "/CompanyProfile" },
        { name: "新闻动态", url: "/News" },
        { name: "发展历程", url: "/History" },
        { name: "加入我们", url: "/Join" },
      ],
      tabstatus: "发展历程",
    };
  },
  methods: {
    TabStyle(val) {
      this.tabstatus = val.name;
      this.$router.push(val.url);
    },
  },
};
</script>

<style scoped lang='less'>
.news{
  background-color: #F5F7FA;
}
//新闻的头部
.news_top {
  width: 100%;
  height: 388px;
  background: url("../assets/images/kehu_bgc.png") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 157px;
  box-sizing: border-box;
  .news_top_title {
    height: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 42px;
  }
  .news_top_content {
    margin: 0 auto;
    font-size: 20px;
    line-height: 40px;
    color: #fff;
  }
}
//切换菜单
.news_tab {
  width: 100%;
  height: 74px;
  background: #ccc;
  .news_tab_list {
    height: 100%;
    display: flex;
    opacity: 1 !important;
    div {
      width: 25%;
      height: 100%;
      line-height: 74px;
      text-align: center;
      color: #222;
      cursor: pointer;
      font-size: 20px;
    }
  }
}

//发展历程
.history_dev {
  padding-top: 91px;
  .history_dev_title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .history_dev_logo {
    text-align: center;
    font-size: 30px;
    color: #999999;
    margin: 10px 0 93px 0;
  }
  .history_dev_module {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    background-color: #fff;
    line-height: 30px;
    padding:0  40px;
    box-sizing: border-box;
  }
}

//菜单激活的样式
.active_news_tab {
  background-color: #fff;
  font-weight: bold;
  font-size: 20px;
  color: #254ED4 !important;
}
</style>