<template>
  <div class="news">
    <div class="news_top">
      <!-- <img src="../assets/images/index_top_bgc.png" alt=""> -->
      <div class="news_top_title">中工科创</div>
      <!-- <div class="news_top_content">
        KSK开放平台中是专注于职业教育培训领域，集线上培训平台建设运营，线下大型教培基
        地整合运作，职业技能培训全生态解决方案打造于一体的高
      </div> -->

             <div class="news_top_content">
       KSK开放平台中是专注于职业教育培训领域，集线上培训平台建设运营，线下大型教培基
      </div>
          <div class="news_top_content">
       地整合运作，职业技能培训全生态解决方案打造于一体的高
      </div>
    </div>

    <div class="news_tab">
      <div class="container news_tab_list">
        <div
          v-for="(item, index) in list"
          :key="index"
          @click="TabStyle(item)"
          :class="tabstatus == item.name ? 'active_news_tab' : ''"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="news_detail container" v-if="tabstatus == '新闻动态'" v-loading="loading">
      <div class="left">
        <!-- <div class="left_top_title">
          <span>KSK开放平台</span> <span>></span> <span>新闻动态</span>
          <span>></span>
          <span style="color: #254ed4">{{ DetailInfo.title }}</span>
        </div> -->
        <div class="title">{{ DetailInfo.title }}</div>
        <div class="author">作者：{{ DetailInfo.author }}</div>
        <div class="time">发布时间：{{ DetailInfo.created_at }}</div>
        <p v-html="DetailInfo.content"></p>
        <div class="source">文章来源：{{ DetailInfo.source }}</div>
      </div>

      <div class="right">
        <div class="right_title">热点新闻</div>
        <div class="right_hot_list">
          <div
            class="list"
            v-for="(item, index) in hotlist"
            :key="index"
            @click="GoNewsDetail(item)"
          >
            <img :src="item.image" alt="" />
            <div class="hot_list_title">
              <div class="hot_list_content">{{ item.title }}</div>
              <div class="hot_list_time">{{ item.created_at }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from "@/API/api";
export default {
  data() {
    return {
      list: [
        { name: "公司简介", url: "/CompanyProfile" },
        { name: "新闻动态", url: "/NewsDetail" },
        { name: "发展历程", url: "/History" },
        { name: "加入我们", url: "/Join" },
      ],
      tabstatus: "新闻动态",
      DetailInfo: {},
      hotlist: [],
      loading: false,
    };
  },
  methods: {
    TabStyle(val) {
      this.tabstatus = val.name;
      this.$router.push(val.url);
    },
    QueryDetail() {
      this.loading = true;
      getNewsDetail({ id: this.id }).then((res) => {
        this.DetailInfo = res.data.detail;
        this.hotlist = res.data.hotList;
        this.loading = false;
      });
    },
    //新闻详情
    GoNewsDetail(val) {
      this.loading = true;
      getNewsDetail({ id: val.id }).then((res) => {
        this.DetailInfo = res.data.detail;
        this.hotlist = res.data.hotList;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.QueryDetail();
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
};
</script>

<style scoped lang='less'>
.news{
  background-color: #F5F7FA;
}
//新闻的头部
.news_top {
  width: 100%;
  height: 388px;
  background: url("../assets/images/kehu_bgc.png") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 157px;
  box-sizing: border-box;
  .news_top_title {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 42px;
  }
  .news_top_content {
    margin: 0 auto;
    font-size: 20px;
    line-height: 40px;
    color: #fff;
  }
}
//切换菜单
.news_tab {
  width: 100%;
  height: 74px;
  background: #ccc;
  .news_tab_list {
    height: 100%;
    display: flex;
    opacity: 1 !important;
    div {
      width: 25%;
      height: 100%;
      line-height: 74px;
      text-align: center;
      color: #222;
      cursor: pointer;
      font-size: 20px;
    }
  }
}
.news_detail {
  display: flex;
  min-height: 100vh;
  padding-bottom: 100px;
  .left {
    width: 65%;
    padding: 41px 200px 0 20px;
    box-sizing: border-box;
    background: linear-gradient(152deg, #f4f6f9 0%, #f9fafc 100%);
    // box-shadow: 0px 0px 3px rgba(20, 53, 193, 0.1);
    border-radius: 2px;
    .left_top_title {
      color: #999999;
      font-size: 16px;
      span {
        margin: 0 7px;
      }
    }
    .title {
      width: 551px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      margin-top: 71px;
    }
    .author {
      font-size: 20px;
      color: #999999;
      margin: 20px 0 10px 0;
    }
    .time {
      font-size: 20px;
      color: #999999;
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
      color: #333333;
      margin-bottom: 50px;
    }
    .source {
      font-size: 16px;
      color: #999999;
    }
  }
  .right {
    width: 511px;
    height: 100%;
    margin-top: 131px;
    padding: 24px 23px 0 35px;
    box-sizing: border-box;

    // border: 3px solid #ffffff;
    background: linear-gradient(152deg, #f4f6f9 0%, #f9fafc 100%);
    // box-shadow: 0px 0px 6px rgba(20, 53, 193, 0.1);
    border-radius: 4px;
    .right_title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
    }
    .right_hot_list {
      width: 100%;
      height: 600px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .list {
        width: 454px;
        height: 98px;
        display: flex;
        margin: 20px 0;
        cursor: pointer;
        background-color: #fff;
        img {
          width: 208px;
          height: 100%;
        }
      }

      .hot_list_title {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 20px;
        box-sizing: border-box;
        .hot_list_content {
          width: 160px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 16px;
          color: #333333;
        }
        .hot_list_time {
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
}

//菜单激活的样式
.active_news_tab {
  background-color: #fff;
  font-weight: bold;
  font-size: 20px;
  color: #254ed4 !important;
}
</style>