<!--
 * @Email: rumosky@163.com
 * @Author: rumosky
 * @Github: https://github.com/rumosky
 * @Date: 2019-07-19 09:32:08
 * @Description: 封装分页组件
 -->
<template>
  <div class="page-wrap">
    <ul>
      <li class="li-page" @click="goPrePage">上一页</li>
      <li
        v-for="(i, index) in showPageBtn"
        :key="index"
        :class="{
          active: i === currentPage,
          pointer: i,
          hover: i && i !== currentPage,
        }"
        @click="pageOffset(i)"
      >
        <a v-if="i">{{ i }}</a>
        <a v-else>···</a>
      </li>
      <li class="li-page" @click="goNextPage">下一页</li>
      <!-- <li class="input_num">
        <input type="text" @blur="inputMaxNum" v-model="inputNum" placeholder="请输入页码">
        <button @click="goNumPage">跳转</button>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    num: Number,
    limit: Number,
  },
  data: () => ({
    offset: 0,
    nowPage: 1,
    inputNum:null,
  }),
  computed: {
    prePage() {
      return this.offset !== 0 && this.num;
    },
    nextPage() {
      return this.offset + this.limit < this.num && this.num;
    },
    totalPage() {
      return Math.ceil(this.num / this.limit);
    },
    currentPage() {
      return Math.ceil(this.offset / this.limit) + 1;
    },
    showPageBtn() {
      const pageNum = this.totalPage;
      const index = this.currentPage;
      if (pageNum <= 5) return [...new Array(pageNum)].map((v, i) => i + 1);
      if (index <= 2) return [1, 2, 3, 0, pageNum];
      if (index >= pageNum - 1)
        return [1, 0, pageNum - 2, pageNum - 1, pageNum];
      if (index === 3) return [1, 2, 3, 4, 0, pageNum];
      if (index === pageNum - 2)
        return [1, 0, pageNum - 3, pageNum - 2, pageNum - 1, pageNum];
      return [1, 0, index - 1, index, index + 1, 0, pageNum];
    },
  },
  methods: {
    pageOffset(i) {
      if (i === 0 || i === this.currentPage) return;
      this.offset = (i - 1) * this.limit;
      this.nowPage = i;
      this.$emit("getNew", i);
    },
    goPrePage() {
      if (!this.prePage) {
        //alert("这里是第一页呀，亲!");
        return;
      }
      this.offset -= this.limit;
      this.nowPage -= 1;
      this.$emit("getNew", this.nowPage);
    },
    goNextPage() {
      // debugger;
      if (!this.nextPage) {
        //alert("已经是最后一页了呢~");
        return;
      }
      this.offset += this.limit;
      this.nowPage += 1;
      this.$emit("getNew", this.nowPage);
    },
    // 输入框最大值限制
    inputMaxNum(){
         if(this.totalPage<this.inputNum){
          this.inputNum = this.totalPage
         }
    },
    // 跳转到某一页
    goNumPage(){
      console.log('kkk')
      this.nowPage = this.inputNum
       this.offset = (this.limit*(this.nowPage-1));
      this.$emit("getNew", this.nowPage);
      // this.$emit("getNew", this.nowPage);
    },
  },
};
</script>

<style scoped lang="less">
.active {
  border-color: #254ed4 !important;
  background-color: #254ed4 !important;
  /* border: 1px solid #254ed4; */
}

.hover {
  color: #fff;
  background-color: #f4f4f5;
  background-color: #254ed4;

}

.active a {
  color: #fff;
}

.page-wrap {
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.page-wrap ul {
  display: inline-block;
  list-style: none;
  overflow: hidden;
  padding-inline-start: 0px;
}

.page-wrap li {
  float: left;
  margin: 0 5px;
  height: 34px;
  line-height: 34px;
  user-select: none;
  border-radius: 2px;
}
.li-page {
  cursor: pointer;
  color: #999;
  /* border: 1px solid #ccc;
   */
  width: 66px;
  height: 34px;
  background: #ffffff;
  border-radius: 1px 1px 1px 1px;
  opacity: 1;
  border: 1px solid #ffffff;
  color: #999;
}
.page-wrap li.li-page {
  padding: 0 8px;
}
.page-wrap li.pointer {
  color: #999;
  width: 34px;
  text-align: center;
  background: #ffffff;

  border: 1px solid #fff;
  cursor: pointer;
}
.input_num{
  input{
    width:90px;
    height: 34px;
    padding: 0 10px;
  }
  button{
    margin-left: 20px ;
    width: 80px;
    height: 34px;
    background: #254ed4;
    color: #fff;
    cursor: pointer;
  }
}
</style>
