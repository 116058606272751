<template>
  <div class="empty_box">
    <div class="empty_cont">
      <p class="img"><img src="@/assets/empty_icon.png" alt="" /></p>
      <p class="empty_text">暂无数据</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "pc-empty_box",

  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.empty_cont {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 150px;
  p.empty_text {
    font-size: 16px;
    margin-top: 25px;
    // font-weight: bold;
    color: #999;
  }
}
</style>
