import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Home from '../views/home.vue'
import CustomerCase from '../views/CustomerCase.vue'
import CompanyProfile from '../views/CompanyProfile.vue'
import News from '../views/News.vue'
import Join from '../views/Join.vue'
import History from '../views/History.vue'
import NewsDetail from '../views/NewsDetail.vue'
import TeacherList  from '../views/teacher/list.vue'
import activityList  from '../views/activity/list.vue'
import activityDetail from '../views/activity/detail.vue'

import Store from '../store'



const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Index,
    children: [{
            path: '/',
            component: Home
        },
        {
            path: 'CustomerCase',
            component: CustomerCase
        },
        {
            path: 'CompanyProfile',
            component: CompanyProfile
        },
        {
            path: 'News',
            component: News
        },
        {
            path: 'Join',
            component: Join
        },
        {
            path: 'History',
            component: History
        },
        {
            path:'NewsDetail',
            component:NewsDetail
        },
        {
            path:'TeacherList',
            component:TeacherList
        },
        {
            path:'activityList',
            component:activityList
        },
        {
            path:'activityDetail',
            component:activityDetail
        },
    ]
},
{
    path:'*',
    redirect:'/index'
} ]

const router = new VueRouter({
    mode: 'history',
    routes,
    base: '/' //这里写你服务器挂载的地址
})

router.beforeEach((to,from,next)=>{
    Store.commit('SetPath',to.path)
    next()
})

export default router