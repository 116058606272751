<template>
  <div>
    <div class="Case_top">
      <div class="Case_top_title">KSK开放平台</div>
      <div class="Case_top_detail">服务用户遍布全国22个省</div>
      <div class="Case_top_detail">
        获得世界500强企业、机关单位、职业院校等用户的一致认可
      </div>
    </div>

    <div class="Case_module container">
      <div class="module_title">KSK赋能企业高技能人才培训</div>
      <div class="case_module_list">
        <div class="module_list">
          <div class="module_list_img no1">
            <img src="../assets/images/case_1.png" alt="" />
          </div>
          <div class="module_list_title">技能强国</div>
          <div class="module_list_content">
            疫情期间，国家发改委、工信部、 人社部和全国总工会等四部委联
            合推出技能强国学习平台，基于 KSK开放平台的技术、内容和运
            营，技能强国面向全国产业工人， 为30+行业匹配上万门课程，每
            周定期大国工匠直播。用户覆盖 全国29个省市8万家企业1000万 用户。
            1年内浏览量超过1.37亿 次，培训人数超过700万人次。
          </div>
          <div class="module_list_bottom" @click="knowMore">了解案例</div>
        </div>

        <div class="module_list">
          <div class="module_list_img">
            <img
              src="../assets/images/安徽五一学堂_logo.png"
              alt=""
              style="width: 200px"
            />
          </div>
          <div class="module_list_title">安徽五一学堂</div>
          <div class="module_list_content">
            基于KSK开放平台的技术、内容和运营，安徽五一学堂采用技术平台+权威内容+专业运营+创新模式有机融合的方式构建统一学习平台，打造集培训、练兵、比赛、晋级“四位一体”，建设统分有序，线上线下融合的技能人才培育机制，打造自主管理，统筹运营的新模式。
          </div>
          <div class="module_list_bottom" @click="knowMore">了解案例</div>
        </div>

        <div class="module_list">
          <div class="module_list_img">
            <img
              src="../assets/images/中建七局_logo.png"
              alt=""
              style="width: 200px"
            />
          </div>
          <div class="module_list_title">中建七局</div>
          <div class="module_list_content">
            基于KSK开放平台的技术、内容和运营，中建七局建设工匠学院线上学习平台，包含上万门课程，覆盖集团公司100+工种/岗位，学员覆盖建筑行业数千家企业，累计培训700万+学员。
          </div>
          <div class="module_list_bottom" @click="knowMore">了解案例</div>
        </div>
      </div>

      <!-- 更多案例 -->
      <div class="module_more">
        <div class="module_title">更多案例</div>
        <div class="module_more_list">
          <div class="module_more_content">
            <img src="../assets/images/case1.jpg" alt="" />
            <img src="../assets/images/case2.jpg" alt="" />
            <img src="../assets/images/case3.jpg" alt="" />
            <img src="../assets/images/case4.jpg" alt="" />
          </div>
          <div class="module_more_content" style="margin-top:50px">
            <img src="../assets/images/case5.jpg" alt="" />
            <img src="../assets/images/case6.jpg" alt="" />
            <img src="../assets/images/case7.jpg" alt="" />
            <img src="../assets/images/case8.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    //了解案例
    knowMore(){
      this.$parent.contactUs();
    }
  }
};
</script>

<style lang='less' scoped>
.Case_top {
  width: 100%;
  height: 388px;
  background: url("../assets/images/kehu_bgc.png") no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 157px;
  box-sizing: border-box;
  // margin-top: 85px;
  .Case_top_title {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  .Case_top_detail {
    margin: 0 auto;
    width: 780px;
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    margin-top: 20px;
  }
}
.Case_module {
  padding-top: 56px;
  .module_title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 106px;
  }
  .case_module_list {
    display: flex;
    justify-content: space-around;
    margin-bottom: 83px;
    .module_list {
      width: 374px;
      height: 493px;
      border: 2px solid #ffffff;
      background: linear-gradient(46deg, #fefefe 0%, #f4f7fc 100%);
      box-shadow: 3px 4px 10px rgba(20, 53, 193, 0.14);
      border-radius: 4px;
    }
    .module_list {
      padding: 41px 77px 50px 62px;
      box-sizing: border-box;
      .module_list_img {
        text-align: center;
        height: 60px;
        line-height: 60px;
        &.no1 img{
          width: 100%;
        }
      }
      .module_list_title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin: 29px 0 42px 0;
      }
      .module_list_content {
        width: 244px;
        height: 210px;
        font-size: 16px;
        color: #333333;
      }
      .module_list_bottom {
        margin-top: 27px;
        height: 19px;
        font-size: 14px;
        color: #1435c1;
        cursor: pointer;
      }
      .module_list_title1 {
        height: 60px;
        line-height: 60px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin-bottom: 20px;
      }
      .module_list_title2,
      .module_list_title3 {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        text-align: center;
      }
    }
  }

  //更多案例
  .module_more {
    margin-bottom: 97px;
    .module_title {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }
    .module_more_list {
      width: 100%;
      height: 355px;
      border: 2px solid #ffffff;
      background-color: #fff;
      // background: linear-gradient(46deg, #fefefe 0%, #f4f7fc 100%);
      box-shadow: 3px 4px 10px rgba(20, 53, 193, 0.14);
      border-radius: 4px;
      justify-content: space-around;
      padding: 60px 60px 60px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .module_more_content {
        display: flex;
        justify-content: space-around;
        img {
          width: 22%;
          height: 70px;
        }
      }
    }
  }
}
</style>